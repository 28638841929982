import resumeInfo from './resumeInfo.directive.html';

class ResumeInfoDirectiveController {
    onAction(action, profile, data) {
        const act =
            data &&
            profile &&
            profile.role === 'editor' &&
            data.default &&
            data.userId !== profile.id
                ? 'view'
                : action;
        this.action({ $action: act });
    }
    togglePublic(data, profile) {
        if (
            data &&
            (data.state === 'private' || data.state === 'public') &&
            profile &&
            (profile.role === 'publisher' || profile.role === 'admin')
        ) {
            data.state = data.state === 'public' ? 'private' : 'public';
            data.$saveSummary();
        }
    }
}
export const resumeInfoDirective = function () {
    return {
        restrict: 'EA',
        template: resumeInfo,
        controller: ResumeInfoDirectiveController,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            data: '=',
            selected: '=',
            action: '&',
        },
    };
};
