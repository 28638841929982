import angular from 'angular';
import uiRouter from 'angular-ui-router';

import projectSettingsController from './projectSettings.controller';
import projectController from './project.controller';
import projectSettingsDialog from './projectSettingsDialog.service';
import projectCreateController from './projectCreate.controller'
import projectEditController from './projectEdit.controller'
import {projectInfoDirective} from './projectInfo.directive'
import './project.scss';

const module = angular.module('vitarum.components.project', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('project', {
            title: 'Project',
            url: '/projects',
            templateUrl: './components/project/project.html',
            controller: projectController,
            controllerAs: 'vm',
            role: ['admin', 'publisher'],
            ncyBreadcrumb: {
                label: 'Projects',
                parent: 'home',
            }
        }).state(
            'projectCreate',{
                title:'Create new project',
                url:'/projects/create',
                templateUrl: './components/project/projectCreate.html',
                controller: projectCreateController,
                controllerAs: 'vm',
                role:['admin','publisher'],
                ncyBreadcrumb: {
                    label: 'New project',
                    parent: 'project',
                }
            }
        ).state(
            'projectEdit',{
                title:'Edit project',
                url:'/projects/edit/:id',
                templateUrl: './components/project/projectEdit.html',
                controller: projectEditController,
                controllerAs: 'vm',
                role:['admin','publisher'],
                resolve:{
                    project:[
                        'ProjectModel',
                        '$stateParams',
                        '$log',
                        '$state',
                        function(ProjectModel,
                        $stateParams,
                        $log,
                        $state,
                    ){
                        return ProjectModel.get($stateParams.id)
                        .catch(response => {
                            if (response.status === 404) {
                                $state.go('notfound');
                            }
                            if (response.status === 403) {
                                $state.go('forbidden');
                            }
                            if (response.status === 401) {
                                $state.go('login');
                            }
                        });
                    }
                    ]
                },
                ncyBreadcrumb: {
                    label: 'Edit project',
                    parent: 'project',
                }
            }
        )     }
]);

module.controller('projectSettingsController', projectSettingsController);
module.controller('ProjectController', projectController);
module.controller('ProjectCreateController', projectCreateController);
module.directive('projectInfo', projectInfoDirective);
module.service('projectSettingsDialog', projectSettingsDialog);

export default module.name;
