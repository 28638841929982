export default class MediaLibraryController {
    constructor(
        $log,
        MediaModel,
        confirmationDialog,
        mediaSettingsDialog,
        $scope
    ) {
        this.$log = $log;
        this.MediaModel = MediaModel;
        this.mediaSettingsDialog = mediaSettingsDialog;
        this.confirmationDialog = confirmationDialog;
        this.media = [];
        this.$scope = $scope;

        this.MediaModel.query().then(media => {
            this.media = media;
        });
    }

    openSettings(mediaToUpdate) {
        //console.log(mediaToUpdate);
        const media = mediaToUpdate || new this.MediaModel();
        this.mediaSettingsDialog.openDialog(media).then(data => {
            const cmd = data.value;

            if (cmd.action === 'updateSettings') {
                this.MediaModel.query().then(mediaItem => {
                    this.media = mediaItem;
                });
            }

            if (cmd.action === 'saveSettings') {
                this.MediaModel.query().then(mediaItem => {
                    this.media = mediaItem;
                });
            } else if (cmd.action === 'delete') {
                const msg = `Are you sure you want to delete the image with name ${mediaToUpdate.name}?`;
                this.confirmationDialog.openDialog(msg).then(res => {
                    if (res.value === 'yes') {
                        // TODO change _id to id
                        mediaToUpdate.id = mediaToUpdate._id;
                        mediaToUpdate.$destroy().then(() => {
                            this.MediaModel.query().then(mediaItems => {
                                this.media = mediaItems;
                            });
                        });
                    }
                });
            }
        });
    }
}
//
//function removeFromArray(arr, media) {
//    for (let i = 0; i < arr.length; i++) {
//        if (arr[i].id === media.id) {
//            arr.splice(i, 1);
//            break;
//        }
//    }
//    return arr;
//}

MediaLibraryController.$inject = [
    '$log',
    'MediaModel',
    'confirmationDialog',
    'mediaSettingsDialog',
    '$scope',
];
