/*

 Example :

 <span vt-editable-text
 ng-model='text'
 editable="vm.editable"
 onaftersave="vm.updateResume()">
 Here comes your text
 </span>

 // optionial attribute
 ellipsis="true"

 */
import './../_editableContent.scss';

export const editableTextDirective = () => ({
        restrict: 'A',
        require: '?ngModel',
        scope: {
            editable: '=',
            onaftersave: '&',
            beforeedit: '&',
            removebackup: '&',
            ellipsis: '=',
        },
        link: (scope, element, attrs, ngModel) => {
            let placeholder = 'Type your text';
            let editable = true;
            let oldValue;
            const ellipsis = !!scope.ellipsis;
            let backup = false;

            activate();

            /////////////////////

            function activate() {
                // don't do anything unless this is actually bound to a model
                if (!ngModel) {
                    return;
                }

                if (element.html() !== '') {
                    placeholder = element.html();
                }

                if (typeof scope.editable !== 'undefined') {
                    editable = scope.editable;
                }

                if (editable) {
                    element.addClass('content-editable');
                }

                if (ellipsis) {
                    element.addClass('content-editable--ellipsis');
                }

                // set editable
                element.attr('contenteditable', editable);

                scope.$on('$destroy', cleanUp);

                // view -> model
                element.on('keydown', onKeyDown);
                element.on('blur', onBlur);
                element.on('focus', () => {
                    removePlaceholder();

                    oldValue = ngModel.$viewValue || element.html();
                });

                element.on('click', () => {
                    if (!backup) {
                        backup = true;
                        scope.beforeedit();
                    }

                    removePlaceholder();
                });

                // model -> view
                ngModel.$render = render;

                // view to model
                ngModel.$parsers.unshift(function(value) {
                    return stripHTML(value);
                });
            }

            function removePlaceholder() {
                if (editable && element.html() === placeholder) {
                    element.html('');
                }
            }

            function onKeyDown(e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                }

                if (e.keyCode === 27) {
                    ngModel.$render();
                    element[0].blur();
                }
            }

            function onBlur() {
                scope.$apply(read);
                if (ngModel.$viewValue !== oldValue) {
                    if (ngModel.$viewValue === placeholder) {
                        ngModel.$setViewValue(null);
                    }
                    scope.onaftersave();
                } else {
                    scope.removebackup();
                }

                backup = false;
            }

            function read() {
                const html = element.html();
                if (html === '') {
                    resetToPlaceholder();
                } else {
                    ngModel.$setViewValue(html);
                }

                ngModel.$render(); // rerender to stripe the html
            }

            function resetToPlaceholder() {
                ngModel.$setViewValue(placeholder);
            }

            function render() {
                let html;

                if (editable) {
                    html = ngModel.$viewValue || placeholder;
                } else {
                    html = ngModel.$viewValue || '';
                }

                const striped = stripHTML(html);
                element.html(striped);
            }

            function cleanUp() {
                element.off('blur');
                element.off('keyup');
            }

            function stripHTML(data) {
                const html = data;
                // eslint-disable-next-line no-undef
                const div = document.createElement('div');
                div.innerHTML = html;
                let text = div.textContent || div.innerText || '';
                text = text.replace(/<\/?[^>]+(>|$)/g, '');
                return text;
            }
        },
    });
