import angular from 'angular';
import resumeSettingsDialog from './resumeSettingsDialog.service';
import confirmationDialog from './confirmationDialog.service';
import bookInterviewDialog from './bookInterviewDialog.service';
import authService from './auth.service';
import FileReaderService from './fileReader.service';

const module = angular.module('vitarum.common.services', []);

module.service('resumeSettingsDialog', resumeSettingsDialog);
module.service('confirmationDialog', confirmationDialog);
module.service('bookInterviewDialog', bookInterviewDialog);
module.service('authService', authService);
module.service('fileReader', FileReaderService);

export default module.name;
