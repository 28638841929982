import projectInfo from './projectInfo.directive.html';

class ProjectInfoDirectiveController {
    constructor(){
        this.data = this.data || {};

    }
    // onAction(action, profile, data) {
    //     const act =
    //         data &&
    //         profile &&
    //         profile.role === 'editor' &&
    //         data.default &&
    //         data.userId !== profile.id
    //             ? 'view'
    //             : action;
    //     this.action({ $action: act });
    // }
}
export const projectInfoDirective = function() {
    return {
        restrict: 'EA',
        template: projectInfo,
        controller: ProjectInfoDirectiveController,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            data: '=',
        },
    };
};
