export default class TagsLibraryController {
    constructor($log, TagModel, tagSettingsDialog, $scope, $rootScope) {
        this.$log = $log;
        this.TagModel = TagModel;
        this.tagSettingsDialog = tagSettingsDialog;
        this.tags = [];
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.limit = 50;
        this.totalTags = 0;
        this.filteredList = [];

        this.$scope.skillFilter = '';

        this.$scope.$watch('skillFilter', newValue => {
            this.filterTags(newValue);
        });

        if (
            this.$rootScope.identity.profile.skillFilter &&
            this.$rootScope.identity.profile.skillFilter !== 'undefined'
        ) {
            this.$scope.skillFilter = this.$rootScope.identity.profile.skillFilter;
        }

        this.filterTags(this.$scope.skillFilter);
    }

    openSettings(tag) {
        const oldName = encodeURIComponent(tag.name); //encode the old name so special chars can be send in the url
        const name = tag.name;
        this.tagSettingsDialog.openDialog(tag).then(data => {
            const cmd = data.value;
            const newName = tag.name;
            if (cmd === 'saveSettings') {
                tag.$store(oldName, newName);
            } else if (cmd === 'cancel') {
                tag.name = name;
            }
        });
    }

    filterTags(filter) {
        this.$rootScope.identity.profile.skillFilter = filter;
        this.TagModel.query({ filter: filter }).then(tags => {
            this.tags = tags;
            this.totalTags = tags.total;
        });
    }

    loadMore() {
        this.limit += 50;
    }
}

TagsLibraryController.$inject = [
    '$log',
    'TagModel',
    'tagSettingsDialog',
    '$scope',
    '$rootScope',
];
