import tagSettingsController from './tagSettings.controller';

export default class TagSettingsDialog {
    constructor(ngDialog, $rootScope) {
        this.ngDialog = ngDialog;
        this.$rootScope = $rootScope;
    }

    openDialog(tag) {
        const newScope = this.$rootScope.$new();
        newScope.tag = tag;

        const dialog = this.ngDialog.open({
            template: './components/tagsLibrary/tagSettings.html',
            controller: tagSettingsController,
            controllerAs: 'vm',
            scope: newScope,
            className: 'vt-dialog',
        });

        return dialog.closePromise;
    }
}

TagSettingsDialog.$inject = ['ngDialog', '$rootScope'];
