import angular from 'angular';
import { personalInfoDirective } from './personalInfo.directive';

const module = angular.module(
    'vitarum.common.directives.personalInfoDirective',
    []
);
module.directive('vtPersonalInfo', personalInfoDirective);

export default module.name;
