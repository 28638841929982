/*

 EXAMPLE: experience date [ Feb 2014 - Present ]

 <div vt-editable-date ng-model="vm.period" editable="true" onaftersave="vm.updateResume()">
     <div>
         <div>{{vm.period | period:'From':'from' }}</div> <!-- dec 2014 -->
         <div>{{vm.period | period:'Until':'until' }}</div>
     </div>
 </div>

 <div vt-editable-date ng-model="vm.period" editable="true" onaftersave="vm.updateResume()">
     <div>
        <span>{{vm.period | period:'From - Until'}}</span> <!-- 2014 - 2015 -->
     </div>
 </div>

 */

import moment from 'moment';
import template from './editableDate.directive.html';
import './editableDate.directive.scss';
import '../_editableContent.scss';

export class EditableDateDirectiveController {
    constructor($scope, $rootScope, $log, $element, $document, $timeout) {
        this.$log = $log;
        this.element = $element;
        this.$scope = $scope;
        this.$document = $document;
        this.isOpen = false;
        this.months = this.generateMonths();
        this.unwatchPeriod = null;
        this.$timeout = $timeout;
        this.maxYear = moment().year();

        // cleanup
        $rootScope.$on('destroy', () => {
            if (this.isOpen === true) {
                $scope.$apply(() => {
                    this.onCancel();
                });
            }
        });

        if (this.editable) {
            this.element.addClass('content-editable');
        }
    }

    toModel(period) {
        const model = this.sanitize(this.ngModel.$modelValue);
        model.from.year = period.from.year;
        model.from.month = period.from.month;
        model.until.year = period.until.year;
        model.until.month = period.until.month;
        model.untilFrom = period.untilFrom;
        if (period.until.isPresent) {
            model.until.year = 9998;
            model.until.month = 0;
        }
        return model;
    }

    init(ngModel) {
        this.ngModel = ngModel;
    }

    onOpen() {
        // when disabled don't open
        if (!this.editable) {
            return;
        }

        // open popup
        this.isOpen = true;

        // get data from ngModel
        this.period = angular.copy(this.ngModel.$modelValue || {});

        // take a copy to restore later when we cancel the popup
        this.clonePeriod = angular.copy(this.period);

        // sanitize input
        this.period = this.sanitize(this.period);
        if (this.period.until.year === 9998) {
            this.period.until.isPresent = true;
            this.period.until.year = null;
            this.period.until.month = null;
        }
        this.period.valid = this.validate(this.period);

        // bind to click outside element, so we can cancel when clicked outside
        this.$document.bind('click', event => this.documentClickBind(event));

        // bind to key down to capture ESC, to close the popup
        this.$document.bind('keydown', event =>
            this.documentKeydownBind(event)
        );

        // watch every change of specified period
        this.unwatchPeriod = this.$scope.$watch(
            'vm.period',
            value => {
                if (!value) return;

                // set the external model to view the update immediate
                // this.ngModel.$setViewValue(this.toModel(value));

                // and validate the period
                this.period.valid = this.validate(value);

                // clear until when present is set
                if (this.period.until.isPresent) {
                    this.period.until.year = null;
                    this.period.until.month = 0;
                }
            },
            true
        );
    }

    onCancel() {
        this.isOpen = false;
        if (this.clonePeriod.from) {
            this.period.from = this.clonePeriod.from;
        }
        if (this.clonePeriod.until) {
            this.period.until = this.clonePeriod.until;
        }

        // stop watching
        this.unwatchPeriod();

        // unbind
        this.$document.unbind('click', event => this.documentClickBind(event));
        this.$document.unbind('keydown', event =>
            this.documentKeydownBind(event)
        );
    }

    onSave() {
        // close popup
        this.isOpen = false;

        // update present
        if (this.period.until.isPresent) {
            this.period.until.year = 9998;
            this.period.until.month = 0;
        }

        // this will trigger a change via the watch so the model gets updated
        this.period.untilFrom = this.formatUntilFrom(this.period);
        this.ngModel.$setViewValue(this.toModel(this.period));

        // trigger callback
        this.$timeout(() => {
            this.onaftersave();
        }, 0);
    }

    documentClickBind(event) {
        if (!this.isOpen) {
            return;
        }

        // popup was open and we clicked on the document
        const containsTarget = this.element[0].contains(event.target);
        if (!containsTarget) {
            // we clicked outside the popup, lets close it
            this.$scope.$apply(() => {
                this.onSave();
            });
        }
    }

    documentKeydownBind(event) {
        if (event.which === 27) {
            this.$scope.$apply(() => {
                this.onCancel();
            });
        }
    }

    formatUntilFrom(period) {
        let untilYear = period.until.year;
        let untilMonth = this.pad(period.until.month, 2);
        let fromYear = period.from.year;
        const fromMonth = this.pad(period.from.month, 2);
        if (!untilYear) untilYear = this.pad(fromYear, 4);
        if (!fromYear) fromYear = this.pad(untilYear, 4);
        if (untilMonth === '00') untilMonth = fromMonth;

        return `${untilYear}${untilMonth}-${fromYear}${fromMonth}`;
    }

    pad(num, width, z = '0') {
        const numStr = `${num  }`;
        return numStr.length >= width
            ? numStr
            : new Array(width - numStr.length + 1).join(z) + numStr;
    }

    validate(period) {
        if (!period) return false;
        if (!period.from && !period.until) return false;
        if (
            period.from &&
            (period.from.year < 1960 || period.from.year > this.maxYear)
        )
            return false;
        if (
            period.until &&
            period.until.year &&
            (period.until.year < 1960 || period.until.year > this.maxYear)
        )
            return false;
        return true;
    }

    sanitize(period = {}) {
        if (!period.from) {
            period.from = { month: 0 };
        }

        if (!period.until) {
            period.until = { month: 0 };
        }
        return period;
    }

    generateMonths() {
        const months = [];
        months.push({
            id: 0,
            name: '',
        });
        for (let i = 0; i < 12; i++) {
            months.push({
                id: i + 1,
                name: moment()
                    .month(i)
                    .format('MMM'),
            });
        }
        return months;
    }
}

export const editableDateDirective = function() {
    return {
        restrict: 'A',
        require: ['?ngModel', 'vtEditableDate'],
        template: template,
        scope: {},
        controller: EditableDateDirectiveController,
        controllerAs: 'vm',
        bindToController: {
            onaftersave: '&',
            editable: '=',
        },
        transclude: true,
        link: (scope, element, attrs, controllers) => {
            const ngModelCtrl = controllers[0];
            const myCtrl = controllers[1];
            myCtrl.init(ngModelCtrl);
        },
    };
};

EditableDateDirectiveController.$inject = [
    '$scope',
    '$rootScope',
    '$log',
    '$element',
    '$document',
    '$timeout',
];
