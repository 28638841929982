import { forEach } from 'underscore';

export default function httpErrorInterceptor($log, $q, toaster) {
    return {
        responseError: function(response) {
            const softTitle = 'Oops, Something went wrong';
            const hardTitle = 'Whaw, unexpected response';

            $log.info(
                `Response received with HTTP status code: ${response.status}`
            );

            if (response.status <= 0) {
                toaster.pop(
                    'warning',
                    softTitle,
                    'The connection to the server is lost.'
                );
            } else if (response.status === 400) {
                let message = '';

                $log.info(response);
                if (response.data.message === 'One or more validation failed') {
                    forEach(response.data.details, function(validationError) {
                        const msg = `${validationError.key} ${validationError.message}`;
                        if (message.length > 0) {
                            message += ', ';
                        }
                        message += msg;
                    });

                    toaster.pop('warning', response.data.message, message);
                }
            } else if (response.status > 400 && response.status < 500) {
                if (!response.config.params && !response.config.headers) {
                    if (
                        !response.config.headers.ignoreErrorHandler ||
                        !response.config.params.ignoreErrorHandler
                    ) {
                        //check if the headers and params for the errorhandler
                        toaster.pop(
                            'warning',
                            softTitle,
                            `The request was invalid or cannot be served [ ${response.status} ]`
                        );
                    }
                }
            } else if (response.status >= 500) {
                toaster.pop(
                    'error',
                    hardTitle,
                    `Something is broken at the server [ ${response.status} ]`
                );
            }

            return $q.reject(response);
        },
    };
}

httpErrorInterceptor.$inject = ['$log', '$q', 'toaster'];
