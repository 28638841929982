import angular from 'angular';
import uiRouter from 'angular-ui-router';

import templateOverviewController from './templateOverview.controller';
import { templateInfoDirective } from './templateInfo.directive';
import './templateOverview.scss';

const module = angular.module('vitarum.components.templateOverview', [uiRouter]);


module.config([
  '$stateProvider',
  $stateProvider => {
    $stateProvider.state('templateOverview', {
      url: '/templates',
      title: 'Templates',
      templateUrl: './components/templateOverview/templateOverview.html',
      controller: templateOverviewController,
      controllerAs: 'vm',
      role: ['admin'],
      ncyBreadcrumb: {
        label: 'Templates'
      }
    })
  }
]);

module.controller('TemplateOverviewController', templateOverviewController);
module.directive('templateInfo', templateInfoDirective);

export default module.name;
