/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
export default function fileSizeFormatter() {
    return function(bytes) {
        if (bytes !== isNaN) {
            let precision = 1;
            if (bytes === 0) {
                return '0 bytes';
            }
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
            if (typeof precision === 'undefined') precision = 1;

            const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
            const number = Math.floor(Math.log(bytes) / Math.log(1024));
            const val = (bytes / Math.pow(1024, Math.floor(number))).toFixed(
                precision
            );

            return (
                `${val.match(/\.0*$/) ? val.substr(0, val.indexOf('.')) : val
                } ${
                units[number]}`
            );
        }
    };
}
