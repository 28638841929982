//import {each} from 'underscore';

export default class UserSettingsController {
    constructor(
        $log,
        $scope,
        $http,
        ngDialog,
        FileUploader,
        MediaModel,
        $rootScope
    ) {
        this.$log = $log;
        this.$scope = $scope;
        this.$http = $http;
        this.ngDialog = ngDialog;
        this.user = $scope.user;
        this.title = 'Edit user';
        this.roles = [];
        this.MediaModel = MediaModel;
        this.$rootScope = $rootScope;

        this.userName = this.user.name;
        this.userRole = this.user.role;
        this.userPicture = this.user.picture;
        this.tempFile = null;
        this.isUploading = false;
        this.currentUser = this.$rootScope.identity.profile;

        this.$scope.uploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            onBeforeUploadItem: item => {
                this.isUploading = true;
                const formData = [['avatar'], []];
                Array.prototype.push.apply(item.formData, formData);
            },
            onSuccessItem: (item, response, status) => {
                if (this.tempFile) {
                    // Cleanup previous uploaded but not saved image
                    deleteFile(this.$http, this.tempFile.fileName);
                }

                if (status === 200 && item.isSuccess) {
                    this.isUploading = false;
                    this.userPicture = `api/file/${response.fileName}`;
                    this.tempFile = response;
                }
            },
            onAfterAddingFile: () => {
                this.isUploading = true;
            },
        });

        this.$scope.uploader.url = '/api/file';

        if (!this.user.id) {
            this.title = 'Add new user';
        }

        this.roles = ['admin', 'editor', 'publisher'];
    }

    saveData(valid) {
        if (!valid) return;

        this.user.name = this.userName;
        this.user.role = this.userRole;
        this.user.picture = this.userPicture;

        //if (this.user.authEmails) {
        //    this.user.authEmails = this.convertToLowerCase(this.user.authEmails);
        //}
        //
        //if (this.user.email) {
        //    this.user.email = this.user.email.toLowerCase();
        //}

        this.ngDialog.close(1, 'saveSettings');
    }

    deleteData() {
        this.ngDialog.close(1, 'deleteUser');
    }

    cancel() {
        if (this.tempFile) {
            // Cleanup previous uploaded but not saved image
            deleteFile(this.$http, this.tempFile.fileName);
        }

        this.ngDialog.close(1, 'cancel');
    }

    convertToLowerCase(emails) {
        const lowercased = [];

        for (let i = 0; i < emails.length; i++) {
            lowercased[i] = emails[i].toLowerCase();
        }
        return lowercased;
    }
}

function deleteFile($http, fileName) {
    $http({
        method: 'DELETE',
        url: `api/file/${fileName}`,
    });
}

UserSettingsController.$inject = [
    '$log',
    '$scope',
    '$http',
    'ngDialog',
    'FileUploader',
    'MediaModel',
    '$rootScope',
];
