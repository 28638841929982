export default function BookModelFactory($modelFactory) {
    const model = $modelFactory('api/book', {
        actions: {
            query: {
                afterRequest: data => data.book,
            },
        },
    });
    return model;
}

BookModelFactory.$inject = ['$modelFactory'];
