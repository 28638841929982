import angular from 'angular';
import period from './period.filter';
import urlMinimizer from './urlMinimizer.filter';
import filesizeFormatter from './filesize.filter';

const module = angular.module('vitarum.common.filters', []);
module.filter('period', period);
module.filter('urlMinimizer', urlMinimizer);
module.filter('filesizeFormatter', filesizeFormatter);

export default module.name;
