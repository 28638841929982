import angular from 'angular';
import sample from './sample';
import deletable from './deletable';
import header from './header';
import popup from './popup';
import editableText from './editableText';
import editableDate from './editableDate';
import editableTextarea from './editableTextarea';
import editableSelect from './editableSelect';
import skillLine from './skillLine';
import skillCircle from './skillCircle';
import skillText from './skillText';
import skillGroup from './skillGroup';
import book from './book';
import editableBook from './editableBook';
import editableCheckbox from './editableCheckbox';
import personalInfo from './personalInfo';
import imagePicker from './imagePicker';
import thumbnail from './thumbnail';
import tagInputEvents from './tagInputEvents';
import vtDraggable from './vtDraggable';

const module = angular.module('vitarum.common.directives', [
    deletable,
    header,
    popup,
    sample,
    editableSelect,
    editableText,
    editableDate,
    editableTextarea,
    imagePicker,
    skillCircle,
    skillLine,
    skillText,
    skillGroup,
    book,
    editableBook,
    personalInfo,
    editableCheckbox,
    tagInputEvents,
    thumbnail,
    vtDraggable,
]);

export default module.name;
