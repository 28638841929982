import angular from 'angular';
import directives from './directives';
import services from './services';
import models from './models';
import filters from './filters';
import httpErrorInterceptor from './core/httpErrorInterceptor';

const module = angular.module('vitarum.common', [
    directives,
    services,
    models,
    filters,
]);

module.factory('httpErrorInterceptor', httpErrorInterceptor);

export default module.name;
