import template from './sample.directive.html';

class SampleDirectiveController {
    constructor($scope, $log) {
        this.$log = $log;
        $log.info('ctrl SampleDirectiveController');
    }

    doClick() {
        this.$log.info('click');
    }
}

export const sampleDirective = function() {
    return {
        restrict: 'EA',
        template: template,
        scope: {},
        controller: SampleDirectiveController,
        controllerAs: 'vm',
        // more info: http://blog.thoughtram.io/angularjs/2015/01/02/exploring-angular-1.3-bindToController.html
        bindToController: {
            title: '@',
            click: '&',
        },
    };
};

SampleDirectiveController.$inject = ['$scope', '$log'];
