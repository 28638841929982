import templateInfo from './templateInfo.directive.html';

class TemplateInfoDirectiveController {
  onAction(action) {
    this.action({$action: action});
  }
}

export const templateInfoDirective = function() {
  return {
    restrict: 'E',
    template: templateInfo,
    controller: TemplateInfoDirectiveController,
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      template: '=',
      action: '&'
    }
  }
}
