export default class ConfirmationDialog {
    constructor($rootScope, ngDialog, $q) {
        this.ngDialog = ngDialog;
        this.$q = $q;
        this.$rootScope = $rootScope;
    }

    openDialog(dialogTitle) {
        const newScope = this.$rootScope.$new();
        newScope.dialogTitle = dialogTitle;

        const dialog = this.ngDialog.open({
            template: `
                <div class="vt-dialog__header">
                    Confirm
                </div>
                {{dialogTitle}}
                <div class="vt-dialog__footer">
                    <button  ng-click="yes()" value="Yes" class="ko-button--danger"> Yes </button>
                    <button  ng-click="no()" value="No" class="ko-button--primary"> No </button>
                </div>`,

            controller: [
                '$scope',
                'ngDialog',
                function($scope, ngDialog) {
                    $scope.no = function() {
                        ngDialog.close(1, 'no');
                    };

                    $scope.yes = function() {
                        ngDialog.close(1, 'yes');
                    };
                },
            ],
            plain: true,
            scope: newScope,
            className: 'vt-dialog',
        });

        return dialog.closePromise;
    }
}

ConfirmationDialog.$inject = ['$rootScope', 'ngDialog', '$q'];
