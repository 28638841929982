import template from './skillLine.directive.html';
import './skillLine.directive.scss';

/*
    <vt-skill-line skill-level="1" max-skill-level="5">
    <vt-skill-line skill-level="1">
    <vt-skill-line>
*/
class SkillLineDirectiveController {
    constructor($scope, $animateCss, $element) {
        this.levelValue = this.levelValue || '0';
        this.levelMaxValue = this.levelMaxValue || '4';

        $scope.$watch('vm.levelValue', value => {
            if (!value) return;
            this.levelPercentage =
                parseInt(this.levelValue, 10) *
                (100 / parseInt(this.levelMaxValue, 10));
        });

        $animateCss($element, {
            event: 'enter',
            structural: true,
        }).start();
    }
}

export const skillLineDirective = function() {
    return {
        restrict: 'E',
        template: template,
        scope: {},
        controller: SkillLineDirectiveController,
        controllerAs: 'vm',
        bindToController: {
            levelValue: '@',
            levelMaxValue: '@',
        },
    };
};

SkillLineDirectiveController.$inject = ['$scope', '$animateCss', '$element'];
