import { findLastIndex } from 'underscore';

import './vtDraggable.directive.scss';

let draggedElem = null;

export const vtDraggableDirective = () => ({
        restrict: 'A',
        scope: {
            onaftersave: '&',
            collection: '=',
            item: '=',
            isdraggable: '=',
        },
        link: (scope, element) => {
            activate();

            scope.$watch('isdraggable', newValue => {
                element.attr('draggable', newValue);
            });

            function activate() {
                if (scope.isdraggable) {
                    element.addClass('vt-draggable');
                    element.attr('draggable', true);

                    element.on('dragstart', handleDragStart);
                    element.on('dragover', handleDragOver);
                    element.on('drop', handleDrop);
                    element.on('dragend', handleDragEnd);
                }
            }

            function handleDragStart(e) {
                this.style.opacity = '0.4'; // this / e.target is the source node.
                draggedElem = scope.item;
                e.dataTransfer.effectAllowed = 'move';
            }

            function handleDragOver(e) {
                if (e.preventDefault) {
                    e.preventDefault(); // Necessary. Allows us to drop.
                }

                e.dataTransfer.dropEffect = 'move'; // See the section on the DataTransfer object.
                return false;
            }

            function handleDrop(e) {
                if (e.stopPropagation) {
                    e.stopPropagation(); // stops the browser from redirecting.
                }

                // Don't do anything if dropping the same column we're dragging.
                if (draggedElem !== scope.item) {
                    const foundDragged = findLastIndex(
                        scope.collection,
                        draggedElem
                    );
                    const foundDraggedOn = findLastIndex(
                        scope.collection,
                        scope.item
                    );
                    const elem = scope.collection[foundDragged];
                    scope.collection.splice(foundDragged, 1);
                    scope.collection.splice(foundDraggedOn, 0, elem);
                }

                return false;
            }

            function handleDragEnd() {
                draggedElem = null;
                this.style.opacity = '1'; // this / e.target is the source node.
                scope.onaftersave();
            }
        },
    });
