import template from './header.directive.html';
import './header.directive.scss';

class HeaderController {
    constructor($state, $rootScope, resumeSettingsDialog, authService) {
        this.$state = $state;
        this.state = $state.current;
        this.$rootScope = $rootScope;
        this.resumeSettingsDialog = resumeSettingsDialog;

        this.isPublic = true;
        if ($state.current.name.search('public') !== 0) {
            this.isPublic = false;
        }

        this.isProfile = false;
        if ($state.current.name.search('profile') !== -1) {
            this.isPublic = false;
            this.isProfile = true;
        }

        this.isUserAccounts = false;
        if ($state.current.name.search('userAccounts') !== -1) {
            this.isUserAccounts = true;
            this.isProfile = false;
        }

        this.authService = authService;
    }

    logout() {
        this.authService.logout();
        this.$state.go('login');
    }
}

HeaderController.$inject = [
    '$state',
    '$rootScope',
    'resumeSettingsDialog',
    'authService',
];

export const headerDirective = () => ({
        restrict: 'E',
        scope: {},
        template: template,
        controller: HeaderController,
        controllerAs: 'vm',
        bindToController: true,
        transclude: true,
    });
