import { find, map, each, findWhere } from 'underscore';

export default class ResumesController {
    constructor(
        $injector,
        resume,
        $scope,
        $element,
        $sce,
        openSelectProjectDialog,
        orderBy
    ) {
        this.$sce = $sce;
        this.$log = $injector.get('$log');
        this.$element = $element;
        this.$stateParams = $injector.get('$stateParams');
        this.$state = $injector.get('$state');
        this.$scope = $scope;
        this.$http = $injector.get('$http');
        this.tempSkill = {};
        this.$rootScope = $injector.get('$rootScope');
        this.$window = $injector.get('$window');
        this.$timeout = $injector.get('$timeout');
        this.orderBy = orderBy;

        this.pasteTags = false;
        this.cssLoaded = false;
        this.openSelectProjectDialog = openSelectProjectDialog;

        this.isPublic = this.$state.current.context.isPublic;
        this.isPreview = this.$state.current.context.isPreview;
        this.isDownload = this.$state.current.context.isDownload;
        this.editable = this.$state.current.context.isEdit;
        this.isDraggable = this.editable;
        this.isLocked = false;

        this.resume = resume;
        this.previousResumeState = angular.copy(this.resume);
        this.resumeStateCollection = [];

        if (this.resume.state === 'public') {
            this.isLocked = true;
        }

        if (!this.resume) {
            // resume does not exist
            return this.$state.go('home');
        }

        if (this.isPublic) {
            const res = this.$stateParams.userName.replace(/-/g, ' ');
            if (res !== this.resume.userName) {
                this.$state.go('notfound');
            }
        }

        this.resumeBlocks = $injector.get('resumeBlocks');
        this.$location = $injector.get('$location');
        this.TagModel = $injector.get('TagModel');
        this.$anchorScroll = $injector.get('$anchorScroll');
        this.resumeSettingsDialog = $injector.get('resumeSettingsDialog');
        this.confirmationDialog = $injector.get('confirmationDialog');
        this.bookInterviewDialog = $injector.get('bookInterviewDialog');
        this.toaster = $injector.get('toaster');
        this.savePending = false;

        if (
            this.editable === true &&
            this.$rootScope.identity.name === 'anonymous'
        ) {
            return this.$state.go('notfound');
        }

        if (!this.resume.settings) {
            this.resume.settings = {};
        }

        this.checkboxValue = this.resume.settings.showProfilePicture || true;
        this.checkboxMessage = 'Show Picture';

        // const url = resume.template.templateUrl;
        // this.templateUrl = `templates/${url}/${url}.html`;

        ///////

        // console.log(this.resume.template.id);

        this.templateHtmlUrl = this.$sce.trustAsResourceUrl(
            `/api/templates/html/${this.resume.template.id}`
        );
        this.templateCssUrl = this.$sce.trustAsResourceUrl(
            `/api/templates/css/${this.resume.template.id}`
        );

        this.$http.get(this.templateCssUrl).then(response => {
            const css = response.data;
            const style = angular.element(`<style>${css}</style>`);
            this.$element.append(style);
            this.cssLoaded = true;
        });

        // const csslink = angular.element(`<link href="${this.templateCssUrl}" rel="stylesheet" type="text/css">`);

        // this.$element.append(csslink);

        ///////

        this.resume.picture =
            this.resume.picture || this.$rootScope.identity.profile.picture;
        this.userName = this.resume.userName || 'No user';

        if (!this.resume.userName) {
            this.resume.picture =
                'https://raw.githubusercontent.com/Euricom/frontend-bootcamp-2014Q4-case/develop/app/assets/img/default_bootcamper_image.png';
        }

        this.$state.current.context.subTitle = this.resume.name;
        this.$state.current.context.editUrl = `#${this.$location.path()}`;
        if (this.isPreview) {
            this.$state.current.context.childTitle = `Preview`;
        }

        this.softSkillLevels = find(
            this.resume.template.levels,
            item => item.levelType === 'softSkills'
        );

        this.skillLevels = find(
            this.resume.template.levels,
            item => item.levelType === 'skills'
        );

        if (this.softSkillLevels) {
            this.softSkillLevels = this.softSkillLevels.items;
        }

        if (this.skillLevels) {
            this.skillLevels = this.skillLevels.items;
        }

        this.$rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
            if (this.savePending) {
                event.preventDefault();
                // keep state for later
                this.stateToGoAfterSave = {
                    state: toState,
                    params: toParams,
                };
            }
        });

        this.$scope.options = {
            minLength: 2,
            onSelect: selectedItem => {
                this.tempSkill.title = selectedItem.value;
                this.updateResume();
            },
        };

        this.$scope.getAutocompleteResults = (query, deferred) => {
            this.loadTags(query).then(items => {
                const data = { results: [] };
                data.results = map(items, item => ({ value: item.text }));

                deferred.resolve(data);
            });
        };
    }

    updateSkill(skill) {
        this.tempSkill = skill;
    }

    paste($tag, expTags) {
        // check if are pasting anything
        if (this.pasteTags) {
            try {
                // if we are pasting an JSON parse it and push each tag in the array
                const tagsJSON = JSON.parse($tag.text);
                each(tagsJSON, item => {
                    const found = findWhere(expTags, { text: item.text });
                    if (!found) {
                        item.highlighted = false;
                        expTags.push(item);
                    }
                });
            } catch (e) {
                if ($tag.text.indexOf(',') !== -1) {
                    const res = $tag.text.split(',');
                    angular.forEach(res, item => {
                        expTags.push({ text: item });
                    });
                } else {
                    // otherwise add a single tag
                    expTags.push($tag);
                }
            }
            return true;
        }
    }

    disableDrag() {
        this.isDraggable = false;
    }

    enableDrag() {
        this.isDraggable = true;
    }

    pasted($tag, tags) {
        // check if just pasted anything and remove the pasted JSON string from the array
        if (this.pasteTags) {
            if ($tag === tags[tags.length - 1]) {
                tags.pop();
            }
            this.pasteTags = false;
        }
    }

    addUndoState() {
        this.resumeStateCollection.push(this.previousResumeState);

        const resumeCopy = angular.copy(this.resume);
        this.previousResumeState = resumeCopy;
    }

    undoLastResumeAction() {
        if (this.resumeStateCollection.length > 0) {
            this.resume = this.resumeStateCollection.pop();
            this.previousResumeState = angular.copy(this.resume);
            this.persistResumeToStore();

            const tempScroll = this.$window.scrollY;
            this.$timeout(() => {
                this.$window.scrollTo(0, tempScroll);
            });
        }
    }

    persistResumeToStore() {
        this.savePending = true;
        this.resume.updatedBy = this.$rootScope.identity.profile.name;

        this.resume.$store().finally(() => {
            this.savePending = false;

            if (this.stateToGoAfterSave) {
                this.$state.go(
                    this.stateToGoAfterSave.state,
                    this.stateToGoAfterSave.params
                );
                this.stateToGoAfterSave = null;
            }
        });
    }

    updateResume() {
        this.persistResumeToStore();
        this.addUndoState();
    }

    addSubBlock(item, type) {
        this.resume.addSubBlock(item, type);
        this.updateResume();
    }

    openSelectProject(exp) {
        this.openSelectProjectDialog.openDialog(exp).then(data => {
            switch (data.value.action) {
                case 'select':
                    this.updateExperienceBlock(exp,data.value.project);
                    break;
                case 'cancel':
                default:
                    break;
            }
        });
    }
    updateExperienceBlock(exp,project) {
        const expToUpdate =this.resume.experiences.find(e => e.id === exp.id);

        expToUpdate.summary = project.summary;
        expToUpdate.companyName = project.title;
        expToUpdate.tags = project.tags;
        this.updateResume();
    }
    loadTags(query) {
        return this.TagModel.query({ filter: query }).then(function(items) {
            const data = map(items, function(item) {
                return {
                    text: item.name,
                };
            });

            return data;
        });
    }

    tagClicked($tag, exp) {
        this.resume.highlightTag($tag, exp);
        this.updateResume();
    }

    addResumeBlock(key, type) {
        this.resume.addBlock(key, type);
        this.updateResume();
    }

    deleteBlock(value, key, item) {
        if (
            key === this.resumeBlocks.references ||
            key === this.resumeBlocks.types.skillMatrix
        ) {
            this.resume.removeSubBlock(value, key, item);
        } else {
            this.resume.removeBlock(value, key);
        }
        this.updateResume();
    }

    gotoAnchor(anchor) {
        this.$location.hash(anchor);
        this.$anchorScroll.yOffset = 170;
        this.$anchorScroll();
    }

    showPage(page) {
        this.$state.go(page, { id: this.resume.id });
    }

    openResumeSettings() {
        const currTemplateId = this.resume.templateId;
        const currUserId = this.resume.userId;
        const resumeToUpdate = this.resume;
        const currResumeName = this.resume.name;

        this.resumeSettingsDialog.openDialog(this.resume).then(data => {
            const command = data.value;
            if (command === 'deleteResume') {
                this.confirmationDialog
                    .openDialog('Are you sure you want to archive this resume?')
                    .then(res => {
                        if (res.value === 'yes') {
                            resumeToUpdate.$destroy();
                            this.$state.go('home');
                        }
                    });
            } else if (command === 'restoreResume') {
                resumeToUpdate.state = 'private';
                resumeToUpdate.$saveSummary();
            } else if (command === 'saveSettings') {
                if (
                    currTemplateId !== this.resume.templateId ||
                    currUserId !== this.resume.userId ||
                    currResumeName !== this.resume.name
                ) {
                    // Template has changed, need to route to new template
                    this.$state.go(`edit`, this.resume, { reload: true });
                }
            }
        });
    }

    checkboxChanged() {
        this.resume.settings.showProfilePicture = !this.resume.settings
            .showProfilePicture;
        this.updateResume();
    }

    openBookInterviewDialog() {
        const resume = this.resume;
        this.bookInterviewDialog.openDialog(resume);
    }
}

ResumesController.$inject = [
    '$injector',
    'resume',
    '$scope',
    '$element',
    '$sce',
    'openSelectProjectDialog',
    'orderByFilter',
];
