import template from './skillGroup.directive.html';
import './skillGroup.directive.scss';

class SkillGroupDirectiveController {
    constructor($scope) {
        this.$scope = $scope;
    }

    updateResume() {
        this.onaftersave();
    }
}

export const skillGroupDirective = function() {
    return {
        restrict: 'E',
        template: template,
        scope: {},
        controller: SkillGroupDirectiveController,
        controllerAs: 'vm',
        bindToController: {
            skillGroupTitle: '=',
            skills: '=',
            onaftersave: '&',
            editable: '=',
        },
        transclude: true,
    };
};

SkillGroupDirectiveController.$inject = ['$scope'];
