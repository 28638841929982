import template from './editableSelect.directive.html';

export class EditableSelectDirectiveController {
    constructor($scope, $log, $document, $element) {
        this.$log = $log;
        this.$scope = $scope;
        this.$document = $document;
        this.$element = $element;
        this.isOpen = false;

        if (!this.levels) {
            this.levels = this.generateLevels();
        }

        if (this.editable) {
            this.$element.addClass('content-editable');
        }
    }

    init(ngModel) {
        this.ngModel = ngModel;
    }

    toModel(skill) {
        const model = this.ngModel.$modelValue;
        model.group = skill.group;
        model.title = skill.title;
        model.level = skill.level;
        return model;
    }

    onOpen() {
        if (this.editable) {
            // open popup
            this.isOpen = true;

            // get data from ngModel
            this.skill = angular.copy(this.ngModel.$modelValue || {});

            // bind to click outside element, so we can cancel when clicked outside
            this.$document.bind('click', event =>
                this.documentClickBind(event)
            );

            // bind to key down to capture ESC, to close the popup
            this.$document.bind('keydown', event =>
                this.documentKeydownBind(event)
            );
        }
    }

    onCancel() {
        // close popup
        this.isOpen = false;

        // unbind
        this.$document.unbind('click', event => this.documentClickBind(event));
        this.$document.unbind('keydown', event =>
            this.documentKeydownBind(event)
        );
    }

    onSave() {
        // close popup
        this.isOpen = false;
        this.ngModel.$setViewValue(this.toModel(this.skill));

        this.onaftersave();
    }

    documentClickBind(event) {
        if (!this.isOpen) {
            return;
        }

        // popup was open and we clicked on the document
        const containsTarget = this.$element[0].contains(event.target);
        if (!containsTarget) {
            // we clicked outside the popup, lets close it
            this.$scope.$apply(() => {
                this.onCancel();
            });
        }
    }

    documentKeydownBind(event) {
        if (event.which === 27) {
            this.$scope.$apply(() => {
                this.onCancel();
            });
        }
    }

    generateLevels() {
        const levels = [];
        for (let i = 1; i < 5; i++) {
            levels.push({
                id: i,
                name: `Level ${  i}`,
            });
        }
        return levels;
    }
}

export const editableSelectDirective = function() {
    return {
        restrict: 'A',
        require: ['?ngModel', 'vtEditableSelect'],
        template: template,
        scope: {},
        controller: EditableSelectDirectiveController,
        controllerAs: 'vm',
        bindToController: {
            onaftersave: '&',
            editable: '=',
            levels: '=',
            levelMaxValue: '=',
        },
        transclude: true,
        link: (scope, element, attrs, controllers) => {
            const ngModelCtrl = controllers[0];
            const myCtrl = controllers[1];
            myCtrl.init(ngModelCtrl);
        },
    };
};

EditableSelectDirectiveController.$inject = [
    '$scope',
    '$log',
    '$document',
    '$element',
];
