class ThumbnailDirectiveController {
    constructor($log, $scope, fileReader) {
        this.$log = $log;
        this.fileReader = fileReader;

        this.getFile = file => {
            this.fileReader.readAsDataURL(file, $scope).then(result => {
                this.ngModel.$setViewValue(result);
            });
        };
    }

    init(model) {
        this.ngModel = model;
    }
}

export const thumbnailDirective = function() {
    return {
        restrict: 'A',
        scope: {},
        require: ['?ngModel', 'vtThumbnail'],
        controller: ThumbnailDirectiveController,
        controllerAs: 'vm',
        link: ($scope, el, attrs, ctrls) => {
            el.bind('change', e => {
                const file = (e.srcElement || e.target).files[0];

                const ngModel = ctrls[0];
                const thumbCtrl = ctrls[1];

                thumbCtrl.init(ngModel);
                thumbCtrl.getFile(file);
            });
        },
    };
};

ThumbnailDirectiveController.$inject = ['$log', '$rootScope', 'fileReader'];
