import moment from 'moment';
import { without, findWhere, filter } from 'underscore';

ResumeModelFactory.$inject = ['$modelFactory', 'TemplateModel', '$q'];
export default function ResumeModelFactory($modelFactory, TemplateModel, $q) {
    const model = $modelFactory('api/resumes', {
        defaults: {
            state: 'private',
            default: false,
            settings: {
                showProfilePicture: true,
            },
        },
        actions: {
            base: {
                beforeRequest: request => {
                    if (!request || !request.data) {
                        return;
                    }
                    // remove unwanted properties
                    delete request.data.selected;
                    delete request.data.template;
                },
            },
            query: {
                afterRequest: data => {
                    const items = data.resumes;
                    items.totalPublic = data.totalPublic;
                    items.totalPrivate = data.totalPrivate;
                    items.totalArchived = data.totalArchived;
                    return items;
                },
            },
            $patch: {
                method: 'PATCH',
                url: '{id}',

                // overrides the root url
                override: false,
            },
        },
        instance: {
            friendlyDate: function() {
                return moment(this.updated).fromNow();
            },
            $saveSummary: function() {
                if (this.id) {
                    return this.$patch();
                }
                return this.$save();
            },
            addBlock: function(key, type) {
                if (!key) {
                    return;
                }

                if (this[key] === undefined) {
                    this[key] = [];
                }

                const obj = {};

                if (key === 'skills' && !type) {
                    return;
                }

                if (
                    type &&
                    (type !== 'softSkills' &&
                        type !== 'summary' &&
                        type !== 'skillMatrix')
                ) {
                    return;
                }

                if (type) {
                    if (type !== 'skillMatrix') {
                        obj.group = type;
                    }

                    obj.level = 0;
                }

                this[key].unshift(obj);

                if (key === 'experiences' || key === 'educations') {
                    this[key][0].period = {};
                    this[key][0].period.untilFrom = '999900-999900';
                }

                if (key === 'experiences') {
                    this[key][0].references = [];
                    this[key][0].tags = [];
                }

                if (key === 'skillGroups') {
                    this[key][0].skills = [];
                }
            },

            addSubBlock: function(item, type) {
                if (!item) {
                    return;
                }

                if (type === 'references') {
                    if (!item.references) {
                        item.references = [];
                    }

                    item.references.unshift({});
                }

                if (type === 'skillMatrix') {
                    if (!item.skills) {
                        item.skills = [];
                    }

                    item.skills.unshift({});

                    item.skills[0].level = 0;
                }
            },

            removeBlock: function(value, key) {
                this[key] = without(this[key], value);
            },

            removeSubBlock: function(value, key, item) {
                // #TODO refactor skill matrix

                // when we have a valid id, search for it
                if (key === 'references') {
                    if (value._id) {
                        item.references = without(
                            item.references,
                            findWhere(item.references, { _id: value._id })
                        );

                        return;
                    }

                    // when the id is null (empty reference) we remove the first one
                    const emptyRefs = filter(item.references, function(ref) {
                        return !ref._id;
                    });
                    item.references = without(item.references, emptyRefs[0]);
                }

                if (key === 'skillMatrix') {
                    if (value._id) {
                        item.skills = without(
                            item.skills,
                            findWhere(item.skills, { _id: value._id })
                        );

                        return;
                    }

                    // when the id is null (empty skills) we remove the first one
                    const emptySkills = filter(item.skills, function(ref) {
                        return !ref._id;
                    });
                    item.skills = without(item.skills, emptySkills[0]);
                }
            },
            highlightTag: function(tag, exp) {
                if (!tag) {
                    return;
                }

                const currentTag = findWhere(exp.tags, tag);

                if (!currentTag.highlighted) {
                    currentTag.highlighted = true;
                } else {
                    currentTag.highlighted = false;
                }
            },

            $store: function() {
                delete this.template;
                return model.$call({
                    method: 'PUT',
                    data: this,
                    url: `api/resumes/${this.id}`,
                    ignoreErrorHandler: true,
                });
            },
        },
        duplicate: function(id, data) {
            return model.$call({
                method: 'POST',
                data: data,
                url: `api/resumes/${id}/duplicate`,
            });
        },
        recommend: function(id, data) {
            return model.$call({
                method: 'POST',
                data: data,
                url: `api/resumes/${id}/recommend`,
            });
        },
        getResumeWithTags: function() {
            return model.$call({
                method: 'GET',
                url: `api/resumesskills`,
            });
        },
        getWithTemplate: function(id, published, download) {
            let resume = null;
            return getResume(id, published, download)
                .then(loadedResume => {
                    resume = loadedResume;
                    return TemplateModel.get(resume.templateId, {
                        ignoreErrorHandler: true,
                    }).catch(err => {
                        if (err.status === 404) {
                            return TemplateModel.get(1);
                        }
                    });
                })
                .then(template => {
                    resume.template = template;
                    return resume;
                })
                .catch(err => $q.reject(err));
        },
    });

    return model;

    function getResume(resumeId, published, download) {
        if (published) {
            return model.$call({
                method: 'GET',
                url: `api/resumes/published/${resumeId}`,
                headers: { ignoreErrorHandler: true },
            });
        } else if (download) {
            return model.$call({
                method: 'GET',
                url: `api/resumes/downloadpdf/${resumeId}`,
                headers: { ignoreErrorHandler: true },
            });
        }
        return model.get(resumeId, { ignoreErrorHandler: true });
    }
}
