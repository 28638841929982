export default class TagSettingsController {
    constructor($log, ngDialog, $scope) {
        this.$log = $log;
        this.ngDialog = ngDialog;
        this.tag = $scope.tag;
        this.title = 'Edit skill';

        this.tagname = this.tag.name;
    }

    saveData(valid) {
        if (!valid) return;

        this.tag.name = this.tagname;
        this.ngDialog.close(1, 'saveSettings');
    }

    cancel() {
        this.ngDialog.close(1, 'cancel');
    }
}

TagSettingsController.$inject = ['$log', 'ngDialog', '$scope'];
