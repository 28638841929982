import angular from 'angular';
import uiRouter from 'angular-ui-router';

import loginController from './login.controller';
import './login.scss';

const module = angular.module('vitarum.components.authtest', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('login', {
            title: 'Login',
            url: '/login',
            templateUrl: './components/login/login.html',
            controller: loginController,
            controllerAs: 'vm',
            ncyBreadcrumb: {
                label: 'Login',
            },
        });
    },
]);

module.controller('LoginController', loginController);

export default module.name;
