export default class LoginController {
    constructor($log, $rootScope, authService, $state) {
        this.$log = $log;
        this.$rootScope = $rootScope;
        this.authService = authService;
        this.$state = $state;
        this.errorMessage = false;
        this.$rootScope.$on('LOGIN-FAILED', function(event, data) {
            // eslint-disable-next-line no-console
            console.log('event', event, data);
        });
        this.errorMessage = !!this.$rootScope.authFailed;
    }

    login() {
        this.authService.authenticate();
    }

    logout() {
        this.authService.logout();
        this.errorMessage = false;
    }
}

LoginController.$inject = ['$log', '$rootScope', 'authService', '$state'];
