export default class ErrorController {
    constructor($stateParams, $rootScope) {
        this.message = '';
        this.profile = $rootScope.identity.name;

        if (this.profile === 'anonymous') {
            this.message = $stateParams.messages.anonymous;
        } else {
            this.message = $stateParams.messages.auth;
        }
    }
}

ErrorController.$inject = ['$stateParams', '$rootScope'];
