import bookInterviewController from '../../components/bookInterview/bookInterview.controller';

export default class BookInterviewDialog {
    constructor($rootScope, ngDialog, $q) {
        this.ngDialog = ngDialog;
        this.$q = $q;
        this.$rootScope = $rootScope;
    }

    openDialog(resume) {
        const newScope = this.$rootScope.$new();
        newScope.resume = resume;

        const dialog = this.ngDialog.open({
            template: './components/bookInterview/bookInterview.html',
            controller: bookInterviewController,
            controllerAs: 'vm',
            scope: newScope,
            className: 'vt-dialog',
        });

        return dialog.closePromise;
    }
}

BookInterviewDialog.$inject = ['$rootScope', 'ngDialog', '$q'];
