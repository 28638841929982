import angular from 'angular';
import uiRouter from 'angular-ui-router';
import modelFactory from 'angular-model-factory';
import loadingBar from 'angular-loading-bar';
import toaster from 'angularjs-toaster';
import ngDialog from 'ng-dialog';
import ngCookies from 'angular-cookies';
import angularStorage from 'angular-storage';
import angularJwt from 'angular-jwt';
import 'ng-tags-input';
import ngMessages from 'angular-messages';
import 'angular-breadcrumb';
import 'angular-file-upload';
import 'angular-szn-autocomplete';
import sanitize from 'angular-sanitize';
import ngScroll from 'angular-scroll';
import ngAnimate from 'angular-animate';
import 'ace-angular';

import 'angular-lock';
import 'angular-auth0';
import 'angular-datepicker';

// styles
import './app.scss';

// constants
import constants from './app.constants';

// components
import components from './components';
import common from './common';
import layout from './layout';
import templates from './templates';
import appComponent from './app.component';

window.Auth0Lock = require('auth0-lock').default;

export default angular
    .module('vitarum', [
        // 3th party
        uiRouter,
        modelFactory.name,
        loadingBar,
        toaster,
        ngDialog,
        ngCookies,
        angularStorage,
        angularJwt,
        'auth0.lock',
        'ngTagsInput',
        ngMessages,
        'ncy-angular-breadcrumb',
        'angularFileUpload',
        'angular-szn-autocomplete',
        sanitize,
        ngScroll,
        ngAnimate,
        'datePicker',
        components,
        common,
        layout,
        templates,
        'ace.angular',
    ])
    .constant('events', constants.errors)
    .constant('resumeBlocks', constants.resumeBlocks)
    .constant('config', {
        baseUrl: 'api',
        defaultPageSize: 20,
    })
    .constant('appVersion', VERSION) // eslint-disable-line  no-undef
    .directive('app', appComponent);
