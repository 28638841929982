import angular from 'angular';
import uiRouter from 'angular-ui-router';
import './searchSkills.scss';
import searchSkillsController from './searchSkills.controller';


const module = angular.module('vitarum.components.searchSkills', [uiRouter]);
module.controller('searchSkillsController', searchSkillsController);

//module.config(['$stateProvider', ($stateProvider) => {
//    $stateProvider
//        .state('searchSkills', {
//            title: 'SearchSkills',
//            url: '/searchSkills',
//            templateUrl: './components/searchSkills/searchSkills.html',
//            controller: searchSkillsController,
//            controllerAs: 'vm',
//            role: ['admin', 'publisher', 'editor'],
//            ncyBreadcrumb: {
//                label: 'Search Skills',
//                parent: 'home',
//            },
//        });
//}]);

export default module.name;
