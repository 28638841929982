/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
import { find } from 'underscore';

export default class HomeController {
    constructor(
        $log,
        $state,
        ResumeModel,
        ngDialog,
        $scope,
        resumeSettingsDialog,
        confirmationDialog,
        $rootScope,
        MediaModel,
        $location
    ) {
        this.$log = $log;
        this.resumes = [];
        this.ngDialog = ngDialog;
        this.ResumeModel = ResumeModel;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.resumeSettingsDialog = resumeSettingsDialog;
        this.confirmationDialog = confirmationDialog;
        this.$location = $location;
        this.totalPublic = 0;
        this.totalPrivate = 0;
        this.totalArchived = 0;

        this.profile = $rootScope.identity.profile;
        this.archived = '!archived';
        this.MediaModel = MediaModel;
        this.updatedBy = $rootScope.identity.profile.name;
        this.loading = true;
        this.publicUrl = null;

        if (this.$rootScope.identity.profile.role === 'editor') {
            this.$scope.searchText = this.$rootScope.identity.profile.name;
        }

        if (
            this.$rootScope.identity.profile.searchFilter &&
            this.$rootScope.identity.profile.searchFilter !== 'undefined'
        ) {
            this.$scope.searchText = this.$rootScope.identity.profile.searchFilter;
        }

        ResumeModel.query({ filter: this.$scope.searchText })
            .then(resumes => {
                this.resumes = resumes;
                this.showTotals(resumes);
            })
            .then(() => {
                this.loading = false;
            });
    }

    searchResume() {
        this.filterResumes(this.$scope.searchText);
    }

    action(resume, action) {
        let act = action;
        if (act === 'default') {
            if (resume.state === 'public') {
                act = 'preview';
            } else if (resume.state === 'private') {
                act = 'edit';
            } else if (resume.state === 'archived') {
                act = 'settings';
            }
        }

        switch (act) {
            case 'edit':
                this.editResume(resume);
                break;
            case 'settings':
                this.openSettings(resume);
                break;
            case 'duplicate':
                this.duplicateResume(resume);
                break;
            case 'preview':
                this.showPreview(resume);
                break;
            case 'view':
                this.showView(resume);
                break;
            case 'recommend':
                this.recommendResume(resume);
                break;
            case 'archive':
                this.archive(resume);
                break;
            default:
                return true;
        }
    }

    showPreview(resume) {
        this.$state.go('preview', resume);
    }

    showView(resume) {
        this.$state.go('view', resume);
    }

    editResume(resume) {
        this.$state.go('edit', resume);
    }


    openSettings(resume) {
        // create new resume when not specified
        const resumeToUpdate = resume || new this.ResumeModel();

        const templateId = resumeToUpdate.templateId;
        const updatedBy = resumeToUpdate.updatedBy;

        // open settings dialog
        this.resumeSettingsDialog.openDialog(resumeToUpdate).then(data => {
            const command = data.value;

            if (command === 'cancel' && resume) {
                resumeToUpdate.templateId = templateId;
            }

            if (command === 'deleteResume') {
                if (resumeToUpdate.state !== 'archived') {
                    resumeToUpdate.$destroy().then(re => {
                        if (re) {
                            this.resumes.push(re);
                            this.filterResumes(this.$scope.searchText);
                        }
                    });
                } else {
                    this.confirmationDialog
                        .openDialog(`Are you sure you want to delete this resume?`)
                        .then(res => {
                            if (res.value === 'yes') {
                                resumeToUpdate.$destroy().then(re => {
                                    if (re) {
                                        this.resumes.push(re);
                                        this.filterResumes(this.$scope.searchText);
                                    }
                                });
                            } else {
                                resumeToUpdate.updatedBy = updatedBy;
                            }
                        });
                }
            }

            if (command === 'restoreResume') {
                this.ResumeModel
                    .getWithTemplate(resumeToUpdate.id)
                    .then(res => {
                        res.state = 'private';
                        res.updatedBy = this.updatedBy;
                        return res.$save();
                    })
                    .then(() => {
                        this.filterResumes(this.$scope.searchText);
                    });
            }

            if (command === 'saveSettings') {
                const isNotNew = find(
                    this.resumes,
                    item => item.id === resumeToUpdate.id
                );
                if (!isNotNew) {
                    this.resumes.push(resumeToUpdate);
                }
                this.filterResumes(this.$scope.searchText);
            }
        });
    }

    filterResumes(searchText = '') {
        this.$rootScope.identity.profile.searchFilter = searchText;
        this.ResumeModel.query({ filter: searchText }).then(resumes => {
            this.resumes = resumes;
            this.showTotals(resumes);
        });
    }

    duplicateResume(resume) {
        return this.ResumeModel.duplicate(resume.id).then(() =>
            // newResume.updatedBy = this.updatedBy;
            // this.resumes.push(newResume);
            this.filterResumes(this.$scope.searchText)
        );
    }

    archive(resume) {
        return resume.$destroy().then(re => {
            if (re) {
                this.resumes.push(re);
                this.filterResumes(this.$scope.searchText);
            }
        });
    }

    showTotals(data) {
        this.totalPublic = data.totalPublic;
        this.totalPrivate = data.totalPrivate;
        this.totalArchived = data.totalArchived;
    }

    recommendResume(resume) {
        //url bepalen
        if (resume.userName) {
            const userName = resume.userName.replace(/\s+/g, '-');
            if (this.$location.protocol() === 'http') {
                if (this.$location.port() === 80) {
                    this.publicUrl = `${this.$location.protocol()}://${this.$location.host()}/#/resumes/public/${userName}/${resume.id}`;
                } else {
                    this.publicUrl = `${this.$location.protocol()}://${this.$location.host()}:${this.$location.port()}/#/resumes/public/${userName}/${resume.id}`;
                }
            } else if (this.$location.protocol() === 'https') {
                if (this.$location.port() === 443) {
                    this.publicUrl = `${this.$location.protocol()}://${this.$location.host()}/#/resumes/public/${userName}/${resume.id}`;
                } else {
                    this.publicUrl = `${this.$location.protocol()}://${this.$location.host()}:${this.$location.port()}/#/resumes/public/${userName}/${resume.id}`;
                }
            }
        }

        const email = '';
        const subject = `Profile ${resume.userName}`;
        const emailBody = `Resume link: ${this
            .publicUrl}%0DDownload link: http://${location.host}/api/resumes/${resume.id}/download`;
        // eslint-disable-next-line no-unused-vars
        const attach = 'http://localhost:8080/api/resumes/1/download';
        // eslint-disable-next-line no-undef
        document.location = `mailto:${email}?subject=${subject}&body=${emailBody}`;
    }
}

HomeController.$inject = [
    '$log',
    '$state',
    'ResumeModel',
    'ngDialog',
    '$scope',
    'resumeSettingsDialog',
    'confirmationDialog',
    '$rootScope',
    'MediaModel',
    '$location',
];
