export default function SampleModelFactory($modelFactory) {
    return $modelFactory('sample', {
        defaults: {
            name: 'new object',
            email: null,
        },
    });
}

SampleModelFactory.$inject = ['$modelFactory'];
