import angular from 'angular';
import home from './home';
import profile from './profile';
import login from './login';
import resumeSettings from './resumeSettings';
import userAccounts from './userAccounts';
import resumes from './resumes';
import error from './error';
import mediaLibrary from './mediaLibrary';
import tagsLibrary from './tagsLibrary';
import statistics from './statistics';
import bookInterview from './bookInterview';
import searchSkills from './searchSkills';
import templateOverview from './templateOverview';
import templates from './templates';
import projects from './project'

const module = angular.module('vitarum.components', [
    home,
    profile,
    resumeSettings,
    login,
    userAccounts,
    resumes,
    error,
    mediaLibrary,
    projects,
    tagsLibrary,
    statistics,
    bookInterview,
    searchSkills,
    templateOverview,
    templates,
]);

export default module.name;
