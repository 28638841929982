import angular from 'angular';
import uiRouter from 'angular-ui-router';

import statisticsController from './statistics.controller';

import './statistics.scss';

const module = angular.module('vitarum.components.statistics', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('statistics', {
            title: 'Statistics',
            url: '/statistics',
            templateUrl: './components/statistics/statistics.html',
            controller: statisticsController,
            controllerAs: 'vm',
            role: ['admin', 'publisher'],
            ncyBreadcrumb: {
                label: 'Statistics',
                parent: 'home',
            },
        });
    },
]);

module.controller('StatisticsController', statisticsController);

export default module.name;
