const constants = {
    errors: {
        errorEvent: 'errorEvent',
    },
    resumeBlocks: {
        educations: 'educations',
        experiences: 'experiences',
        skills: 'skills',
        bookshelf: 'bookshelf',
        hobbies: 'hobbies',
        skillGroups: 'skillGroups',
        references: 'references',
        types: {
            summary: 'summary',
            softSkills: 'softSkills',
            skillMatrix: 'skillMatrix',
        },
    },
};

export default constants;
