import angular from 'angular';
import uiRouter from 'angular-ui-router';

import './profile.scss';

const module = angular.module('vitarum.components.profile', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('profile', {
            title: 'Profile',
            url: '/profile',
            templateUrl: './components/profile/profile.html',
            role: ['admin', 'publisher', 'editor'],
            ncyBreadcrumb: {
                label: 'Profile',
                parent: 'home',
            },
        });
    },
]);

export default module.name;
