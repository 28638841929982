export default class TemplateOverviewController {
  constructor(
    $scope,
    TemplateModel,
    $state,
    $injector,
  ) {
    this.templates = [];
    this.$scope = $scope;
    this.TemplateModel = TemplateModel;
    this.$state = $state;

    this.confirmationDialog = $injector.get('confirmationDialog');

    this.queryTemplates();
  }

  action(template, action) {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'edit':
        this.editTemplate(template);
        break;
      case 'duplicate':
        this.duplicateTemplate(template);
        break;
      case 'delete':
        this.deleteTemplate(template);
        break;
    }
  }

  editTemplate(template) {
    this.$state.go('editTemplate', template);
  }

  duplicateTemplate(template) {
    this.TemplateModel.duplicate(template.id)
      .then(() => {
        this.queryTemplates();
      });
  }

  addNewTemplate() {
    this.TemplateModel.new()
      .then(() => {
        this.queryTemplates();
      });
  }

  queryTemplates() {
    this.loading = true;
    this.TemplateModel.query()
      .then(templates => {
        this.templates = templates;
        this.loading = false;
      });
  }

  deleteTemplate(template) {
    this.confirmationDialog
      .openDialog('Are your sure you want to delete this template?')
      .then(res => {
        if (res.value === 'yes') {
          return this.TemplateModel.remove(template.id);
        }
      })
      .then(() => {
        this.queryTemplates();
      });
  }
}

TemplateOverviewController.$inject = ['$scope', 'TemplateModel', '$state', '$injector'];
