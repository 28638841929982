import angular from 'angular';
import uiRouter from 'angular-ui-router';

import mediaSettingsController from './mediaSettings.controller';
import mediaLibraryController from './mediaLibrary.controller';
import mediaSettingsDialog from './mediaSettingsDialog.service';

import './mediaLibrary.scss';

const module = angular.module('vitarum.components.mediaLibrary', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('media', {
            title: 'Certificates',
            url: '/certificates',
            templateUrl: './components/mediaLibrary/mediaLibrary.html',
            controller: mediaLibraryController,
            controllerAs: 'vm',
            role: ['admin', 'publisher'],
            ncyBreadcrumb: {
                label: 'Certificates',
                parent: 'home',
            },
        });
    },
]);

module.controller('MediaSettingsController', mediaSettingsController);
module.controller('MediaLibraryController', mediaLibraryController);
module.service('mediaSettingsDialog', mediaSettingsDialog);

export default module.name;
