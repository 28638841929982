//import {each} from 'underscore';

export default class MediaSettingsController {
    constructor($log, $scope, $http, ngDialog, FileUploader, MediaModel) {
        this.$log = $log;
        this.$scope = $scope;
        this.$http = $http;
        this.ngDialog = ngDialog;
        this.title = 'Update certificate';
        this.file = $scope.file;
        this.MediaModel = MediaModel;
        this.alias = this.file.name;
        this.mediaPicture = this.file.fileName;
        this.imageSrc = this.file.fileName;
        this.isUploading = false;
        this.fileSize = 0;
        this.tempFile = null;

        this.$scope.uploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            onAfterAddingFile: () => {
                this.isUploading = true;
            },
            onErrorItem: () => {
                this.mediaPicture = null;
                this.imageSrc = null;
                this.isUploading = false;
            },
            onSuccessItem: (item, response, status) => {
                if (this.tempFile) {
                    // Cleanup previous uploaded but not saved image
                    deleteFile(this.$http, this.tempFile.fileName);
                }

                if (status === 200 && item.isSuccess) {
                    this.tempFile = response;
                    this.isUploading = false;
                    this.mediaPicture = `api/file/${response.fileName}`;
                    this.imageSrc = `api/file/${response.fileName}`;
                    this.fileSize = response.size;
                }
            },
        });

        this.$scope.uploader.url = '/api/file';
        this.$scope.uploader.queueLimit = 1;

        if (!this.file._id) {
            this.title = 'Add new certificate';
        }
    }

    saveData(valid) {
        if (!valid) return;
        this.file.fileName = this.mediaPicture;

        if (this.file._id) {
            this.file.name = this.alias;
            this.file.size = this.fileSize;
            this.file.update(this.file).then(() => {
                this.ngDialog.close(1, { action: 'updateSettings' });
            });
        } else {
            const media = new this.MediaModel({
                name: this.alias,
                fileName: this.file.fileName,
                type: 'certificate',
                size: this.fileSize,
            });
            media.$save().then(() => {
                this.ngDialog.close(1, { action: 'saveSettings' });
            });
        }
    }

    deleteData() {
        if (!this.file) {
            return;
        }
        this.ngDialog.close(1, { action: 'delete' });
    }

    cancel() {
        if (this.tempFile) {
            // Cleanup previous uploaded but not saved image
            deleteFile(this.$http, this.tempFile.fileName);
        }
        this.ngDialog.close(1, { action: 'cancel' });
    }
}

function deleteFile($http, fileName) {
    $http({
        method: 'DELETE',
        url: `api/file/${fileName}`,
    });
}

MediaSettingsController.$inject = [
    '$log',
    '$scope',
    '$http',
    'ngDialog',
    'FileUploader',
    'MediaModel',
];
