import { findWhere } from 'underscore';

export default class ResumeSettingsController {
    constructor(
        $scope,
        $log,
        TemplateModel,
        UserModel,
        ResumeModel,
        ngDialog,
        $rootScope,
        $location,
        confirmationDialog
    ) {
        this.$log = $log;
        this.resume = $scope.resume;
        this.templateList = [];
        this.userList = [];
        this.ngDialog = ngDialog;
        this.title = 'Change resume settings';
        this.$scope = $scope;
        this.isPublic = false;
        this.publicUrl = null;
        this.tempGuid = this.resume.publicId;
        this.confirmationDialog = confirmationDialog;
        this.ResumeModel = ResumeModel;
        this.profile = $rootScope.identity.profile;
        this.updatedBy = $rootScope.identity.profile.name;
        this.isDeletable = true;
        this.isDefault = false;

        if (!this.resume.id) {
            this.title = 'Add new resume';
        } else {
            this.isPublic = this.resume.state === 'public';
            this.resumeName = this.resume.name;
            this.isDefault = this.resume.default;

            if (this.resume.userName) {
                const userName = this.resume.userName.replace(/\s+/g, '-');
                if ($location.protocol() === 'http') {
                    if ($location.port() === 80) {
                        this.publicUrl = `${$location.protocol()}://${$location.host()}/#/resumes/public/${userName}/${this
                            .resume.id}`;
                    } else {
                        this.publicUrl = `${$location.protocol()}://${$location.host()}:${$location.port()}/#/resumes/public/${userName}/${this
                            .resume.id}`;
                    }
                } else if ($location.protocol() === 'https') {
                    if ($location.port() === 443) {
                        this.publicUrl = `${$location.protocol()}://${$location.host()}/#/resumes/public/${userName}/${this
                            .resume.id}`;
                    } else {
                        this.publicUrl = `${$location.protocol()}://${$location.host()}:${$location.port()}/#/resumes/public/${userName}/${this
                            .resume.id}`;
                    }
                }
            }
        }

        //fill lists
        TemplateModel.query().then(templates => {
            this.templateList = templates;
            if (this.resume) {
                this.resumeTemplate = findWhere(this.templateList, {
                    id: this.resume.templateId,
                });
            }
        });

        if (this.profile.role !== 'editor') {
            UserModel.query().then(users => {
                this.userList = users;

                if (this.resume) {
                    this.resumeUser = findWhere(this.userList, {
                        id: this.resume.userId,
                    });
                }
            });
        }
    }

    close() {
        this.ngDialog.close(1);
    }

    cancel() {
        this.ngDialog.close(1, 'cancel');
    }

    saveData(valid) {
        if (!valid) return;

        const resumeUser = findWhere(this.userList, { id: this.resume.userId });
        if (resumeUser) {
            this.resume.userName = resumeUser.name;
            this.resume.userId = resumeUser.id;
            this.resume.picture = resumeUser.picture;
        }

        if (this.profile.role === 'editor') {
            this.resume.userId = this.profile.id;
            this.resume.userName = this.profile.name;
        }

        if (this.isPublic) {
            this.resume.state = 'public';
        } else {
            this.resume.state = 'private';
        }

        this.resume.publicId = this.tempGuid;
        this.resume.name = this.resumeName;
        this.resume.default = this.isDefault;

        const summary = new this.ResumeModel({
            id: this.resume.id,
            userId: this.resume.userId,
            name: this.resume.name,
            userName: this.resume.userName,
            state: this.resume.state,
            templateId: this.resume.templateId,
            publicId: this.resume.publicId,
            username: this.resume.username,
            picture: this.resume.picture,
            settings: this.resume.settings,
            updatedBy: this.updatedBy,
            default: this.resume.default,
        });

        summary.$saveSummary().then(data => {
            this.resume.id = data.id;
            this.ngDialog.close(1, 'saveSettings');
        });
    }

    deleteData() {
        this.ngDialog.close(1, 'deleteResume');
    }

    restoreData() {
        this.ngDialog.close(1, 'restoreResume');
    }
}

ResumeSettingsController.$inject = [
    '$scope',
    '$log',
    'TemplateModel',
    'UserModel',
    'ResumeModel',
    'ngDialog',
    '$rootScope',
    '$location',
    'confirmationDialog',
];
