import { find } from 'underscore';

import template from './skillText.directive.html';

class SkillTextDirectiveController {
    constructor($scope) {
        this.levelValue = this.levelValue || '0';

        $scope.$watch('vm.levelValue', value => {
            if (!value) return;

            if (this.levels) {
                const skill = find(this.levels, item => item.level === Number(this.levelValue));
                if (skill) {
                    this.skillText = skill.name;
                } else {
                    this.skillText = 'Level';
                }
            }
        });
    }
}

export const skillTextDirective = function() {
    return {
        restrict: 'E',
        template: template,
        scope: {},
        controller: SkillTextDirectiveController,
        controllerAs: 'vm',
        bindToController: {
            levelValue: '@',
            levels: '=',
        },
    };
};

SkillTextDirectiveController.$inject = ['$scope'];
