import template from './personalInfo.directive.html';
import './personalInfo.directive.scss';

class PersonalInfoController {
    constructor() {
        this.iconHolder = `ko-icon--${this.icon}` || 'ko-icon--user';
        this.title = this.title || 'Title';
        this.year = 40;
        this.placeholder = this.placeholder || 'Please fill in this form';
    }
}

export const personalInfoDirective = function() {
    return {
        restrict: 'E',
        template: template,
        controller: PersonalInfoController,
        replace: true,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            icon: '@',
            title: '@',
            placeholder: '@',
        },
        transclude: true,
    };
};
