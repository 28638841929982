export default class UserAccountsController {
    constructor(
        $log,
        UserModel,
        MediaModel,
        userSettingsDialog,
        confirmationDialog,
        $rootScope,
        store,
        $scope
    ) {
        this.$log = $log;
        this.UserModel = UserModel;
        this.userSettingsDialog = userSettingsDialog;
        this.confirmationDialog = confirmationDialog;
        this.users = [];
        this.MediaModel = MediaModel;
        this.$rootScope = $rootScope;
        this.store = store;
        this.$scope = $scope;

        this.$scope.userFilter = '';

        this.$scope.$watch('userFilter', newValue => {
            this.storeFilter(newValue);
        });

        if (
            this.$rootScope.identity.profile.userFilter &&
            this.$rootScope.identity.profile.userFilter !== 'undefined'
        ) {
            this.$scope.userFilter = this.$rootScope.identity.profile.userFilter;
        }

        UserModel.query().then(users => {
            this.users = users;
        });

        this.currentUser = this.$rootScope.identity.profile;
    }

    openSettings(user) {
        const userToUpdate = user || new this.UserModel();

        // copy user move to cancel
        const name = userToUpdate.name;
        const role = userToUpdate.role;
        const email = userToUpdate.email;
        const auth = userToUpdate.authEmails;
        const picture = userToUpdate.picture;

        this.userSettingsDialog.openDialog(userToUpdate).then(data => {
            const cmd = data.value;
            if (cmd === 'deleteUser') {
                const msg = `Are you sure you want to delete ${user.role} ${user.name}?`;
                this.confirmationDialog.openDialog(msg).then(res => {
                    if (res.value === 'yes') {
                        userToUpdate.$destroy();
                    }
                });
            } else if (cmd === 'saveSettings') {
                const isNew = angular.isUndefined(userToUpdate.id);
                userToUpdate.$save();

                if (userToUpdate.id === this.currentUser.id) {
                    this.currentUser.picture = userToUpdate.picture;
                    this.currentUser.name = userToUpdate.name;
                    this.store.set('vt-identity', this.$rootScope.identity);
                }

                if (isNew) {
                    this.users.push(userToUpdate);
                }
            } else {
                userToUpdate.name = name;
                userToUpdate.role = role;
                userToUpdate.email = email;
                userToUpdate.authEmails = auth;
                userToUpdate.picture = picture;
            }
        });
    }

    storeFilter(filter) {
        this.$rootScope.identity.profile.userFilter = filter;
    }
}

UserAccountsController.$inject = [
    '$log',
    'UserModel',
    'MediaModel',
    'userSettingsDialog',
    'confirmationDialog',
    '$rootScope',
    'store',
    '$scope',
];
