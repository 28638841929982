import angular from 'angular';

angular
    .module('vitarum')
    .run(registerStateEvents)
    .run(authInit);

authInit.$inject = ['authService'];
function authInit(authService) {
    authService.init();
}

registerStateEvents.$inject = [
    '$rootScope',
    '$log',
    '$state',
    'appVersion',
    'ngDialog',
];
function registerStateEvents($rootScope, $log, $state, appVersion, ngDialog) {
    $rootScope.$on('$stateNotFound', (event, unfoundState) => {
        $log.warn('state not found: ', unfoundState.to);
    });

    $rootScope.$on(
        '$stateChangeError',
        (event, toState, toParams, fromState, fromParams, error) => {
            $log.warn('state changeError: ', error);
        }
    );
    $rootScope.$on('$stateChangeStart', (event, toState) => {
        if (toState.role && !$rootScope.identity.isAuthenticated()) {
            event.preventDefault();
            $state.go('login');
            return;
        }
        if (toState.role && !$rootScope.identity.isInRole(toState.role)) {
            event.preventDefault();
            $state.go('forbidden');
        }
    });
    $rootScope.$on('$stateChangeSuccess', (event, toState) => {
        $rootScope.pageTitle = `${toState.title} v${appVersion}`;
        $rootScope.pageState = toState.name;
        scroll(0, 0); // eslint-disable-line
        ngDialog.closeAll();
    });
}
