import projectSettingsController from './projectSettings.controller';

export default class ProjectSettingsDialog {
    constructor($rootScope, ngDialog, $q) {
        this.ngDialog = ngDialog;
        this.$q = $q;
        this.$rootScope = $rootScope;
    }

    openDialog(project) {
        const newScope = this.$rootScope.$new();
        newScope.project = project;

        const dialog = this.ngDialog.open({
            template: './components/project/projectSettings.html',
            controller: projectSettingsController,
            controllerAs: 'vm',
            scope: newScope,
            className: 'vt-dialog vt-dialog-projects',
        });

        return dialog.closePromise;
    }
}

ProjectSettingsDialog.$inject = ['$rootScope', 'ngDialog', '$q'];
