import { map, findWhere } from 'underscore';

export default class ProjectSettingsController {
    constructor($log, $scope, $http, ngDialog, ProjectModel, $rootScope) {
        this.$log = $log;
        this.$scope = $scope;
        this.$http = $http;
        this.ngDialog = ngDialog;
        this.project = $scope.exp || {};
        this.title = 'Edit project';
        this.roles = [];
        this.$rootScope = $rootScope;
        this.ProjectModel = ProjectModel;
        this.tempFile = null;
        this.isUploading = false;
        this.currentUser = this.$rootScope.identity.profile;
        this.projects = [];
        this.projectSelected;
        // this.$scope.projectSelectOptions = {
        //     minLength: 2,

        //     onSelect: selectedItem => {
        //         this.ProjectModel.get(selectedItem.id).then(result=>{
        //             this.project =result;
        //         })
        //         //this.updateResume();
        //     },
        // };
        this.roles = ['admin', 'editor', 'publisher'];
        // this.$scope.getAutocompleteResults = (query, deferred) => {
        //     this.loadProjects(query).then(items => {
        //         const data = { results: [] };
        //         data.results = map(items, item => ({
        //             value: item.title,
        //             id: item.id,
        //         }));

        //         deferred.resolve(data);
        //      });
        // };

        this.ProjectModel.query({}).then(projects => {
            this.projects = projects;
            if (this.project) {
                this.projectSelected = findWhere(this.projects, {
                    title: this.project.companyName,
                });
                console.dir(this.projectSelected);
            }

            // if (this.resume) {
            //     this.resumeUser = findWhere(this.userList, {
            //         id: this.resume.userId,
            //     });
            // }
        });
    }

    selectChanged() {
        this.ProjectModel.get(this.projectSelected.id).then(result => {
            this.project = result;
        });
    }

    loadProjects(query) {
        return this.ProjectModel.query({ filter: query }).then(function(items) {
            const data = map(items, function(item) {
                return {
                    title: item.title,
                    id: item.id,
                };
            });

            return data;
        });
    }

    saveData(valid) {
        if (!valid) return;
        this.ngDialog.close(1, { action: 'select', project: this.project });
    }

    cancel() {
        this.ngDialog.close(1, 'cancel');
    }
}

ProjectSettingsController.$inject = [
    '$log',
    '$scope',
    '$http',
    'ngDialog',
    'ProjectModel',
    '$rootScope',
];
