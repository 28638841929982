import moment from 'moment';

export default function ProjectModelFactory($modelFactory) {
    const model = $modelFactory('api/project', {
        // options
        // defaults: {
        //     role: 'editor',
        //     picture: '../../assets/avatar_placeholder.png',
        // },
        actions: {
            base:{
                beforeRequest: request => {
                    if (!request || !request.data) {
                        return;
                    }
                    // remove unwanted properties
                    delete request.data.title;
                },
            },
            query: {
                afterRequest: data => data.projects,
            },
            get: {
                afterRequest: data => data
            },
        },
        instance: {
            friendlyDate: function() {
                return moment(this.updated).fromNow();
            },

        },
        getById: function(projectId){
            return model.$call({
                method: 'GET',
                url: `api/project/${projectId}`,
                headers: { ignoreErrorHandler: true },
            });
        }
    });

    return model;
}

ProjectModelFactory.$inject = ['$modelFactory'];
