import './deletable.directive.scss';

export const deletableDirective = function($compile, $parse) {
    let removePanelFn = null;
    return {
        restrict: 'A',
        compile: () => ({
                pre: () => {
                    //#TODO refactor stopPropagation
                    const template = `
                        <div class="vt-deletable ko-button--danger" ng-click="deleteBlock(); $event.stopPropagation()">
                           <div class="ko-icon--plus"></div>
                        </div>`;
                    removePanelFn = $compile(template);
                },
                post: (scope, element, attrs) => {
                    const deletable = $parse(attrs.deletable)(scope);
                    const onAfterDelete = $parse(attrs.onafterdelete);
                    const beforeDelete = $parse(attrs.beforedelete);

                    if (deletable) {
                        const newScope = scope.$new();
                        removePanelFn(newScope, panel => {
                            element.addClass('vt-deletable-parent');
                            element.append(panel);
                        });

                        newScope.deleteBlock = function deleteBlock() {
                            beforeDelete(scope);
                            onAfterDelete(scope);
                        };
                    }
                },
            }),
    };
};

deletableDirective.$inject = ['$compile', '$parse'];
