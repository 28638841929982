export default function MediaModelFactory($modelFactory) {
    const model = $modelFactory('api/media', {
        actions: {
            query: {
                afterRequest: data => data.fileNames,
            },
        },
        instance: {
            $remove: (id, name) => model.$call({
                    method: 'PUT',
                    data: { name: name },
                    url: `api/media/${id}`,
                }),
            $save: () => model.$call({
                    medthod: 'POST',
                    data: {
                        name: model.name,
                        fileName: model.fileName,
                        type: model.type,
                        size: model.size,
                    },
                    url: `api/media`,
                }),
            update: media => model.$call({
                    method: 'PATCH',
                    data: {
                        name: media.name,
                        fileName: media.fileName,
                        type: media.type,
                        size: media.size,
                    },
                    url: `api/media/${media._id}`,
                }),
        },
    });

    return model;
}

MediaModelFactory.$inject = ['$modelFactory'];
