import moment from 'moment';
import template from './book.directive.html';
import './book.directive.scss';

/*
 <vt-book ng-model="vm.book"></vt-book>
 */

class BookDirectiveController {
    constructor($scope) {
        this.$scope = $scope;
        this.$scope.friendlyDate = this.friendlyDate;
        this.$scope.isEmpty = this.isEmpty;
        //this.$scope.empty = false;

        if (this.$scope.book === undefined) {
            this.$scope.book = [];
        }

        //if (this.$scope.book && !this.$scope.book.title) {
        //    this.$scope.empty = true;
        //}
    }

    friendlyDate(date) {
        if (!date) {
            return null;
        }
        return moment(date).format('LL');
    }
}

BookDirectiveController.$inject = ['$scope'];

export const bookDirective = function() {
    return {
        restrict: 'E',
        template: template,
        scope: {
            book: '=ngModel',
            editable: '=editable',
        },
        replace: true,
        controller: BookDirectiveController,
    };
};
