import angular from 'angular';
import SampleModel from './sample.model';
import UserModel from './user.model';
import ResumeModel from './resume.model';
import TemplateModel from './template.model';
import TagModel from './tag.model';
import BookModel from './book.model';
import MediaModel from './media.model';
import ProjectModel from './project.model';
import StatisticsModel from './statistics.model';


const module = angular.module('vitarum.common.models', []);
module.factory('SampleModel', SampleModel);
module.factory('UserModel', UserModel);
module.factory('ResumeModel', ResumeModel);
module.factory('TemplateModel', TemplateModel);
module.factory('TagModel', TagModel);
module.factory('BookModel', BookModel);
module.factory('MediaModel', MediaModel);
module.factory('ProjectModel', ProjectModel);
module.factory('StatisticsModel', StatisticsModel);

export default module.name;
