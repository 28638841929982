import angular from 'angular';
import uiRouter from 'angular-ui-router';
import templatesController from './templates.controller';
import './templates.scss';

const module = angular.module('vitarum.conponents.templates', [uiRouter]);


module.config([
  '$stateProvider',
  $stateProvider => {
    $stateProvider.state('editTemplate', {
      url: '/templates/:id',
      title: 'Template editor',
      templateUrl: './components/templates/templates.html',
      controller: templatesController,
      controllerAs: 'vm',
      role: ['admin'],
      ncyBreadcrumb: {
        label: '{{vm.template.name}}',
        parent: 'templateOverview',
      },
      resolve: {
        template: [
          'TemplateModel',
          '$stateParams',
          '$state',
          '$rootScope',
          (TemplateModel, $stateParams, $state, $rootScope) => loadData(TemplateModel, $stateParams.id, $state, $rootScope)
        ]
      },
    });
    function loadData(TemplateModel, templateId, $state, $rootScope) {
      return TemplateModel.get(templateId)
        .then(template => {
          if (!template || $rootScope.identity.profile.role !== 'admin') {
            $state.go('templateOverview');
          }

          return template;
        })
        .catch(response => {
          // eslint-disable-next-line default-case
          switch(response.status) {
            case 404:
            case 403:
            case 401:
              $state.go('templateOverview');
              break;
          }
        })
    }
  }
]);

module.controller('TemplatesController', templatesController);

export default module.name;
