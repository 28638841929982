import angular from 'angular';
import uiRouter from 'angular-ui-router';

import errorController from './error.controller';
import './error.scss';

const module = angular.module('vitarum.components.error', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider
            .state('notfound', {
                title: 'Not Found 404',
                url: '/error/notfound',
                params: {
                    type: 'notFound',
                    messages: {
                        anonymous: `Oops! This resume doesn't exist.`,
                        auth: 'Oops! This page does not exist.',
                    },
                },
                templateUrl: './components/error/error.html',
                controller: errorController,
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: 'Error 404',
                    parent: 'home',
                },
            })
            .state('forbidden', {
                title: 'Forbidden 403',
                url: '/error/forbidden',
                params: {
                    type: 'forbidden',
                    messages: {
                        anonymous: `Sorry, this resume is no longer available online.<br> For further assistance contact Euricom.`,
                        auth: `Sorry, this resume is no longer available online.<br> For further assistance contact Euricom.`,
                        //'auth': 'You are not authorized to view this page.',
                    },
                },
                templateUrl: './components/error/error.html',
                controller: errorController,
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: 'Error 403',
                    parent: 'home',
                },
            });
    },
]);

module.controller('ErrorController', errorController);

export default module.name;
