import angular from 'angular';
import uiRouter from 'angular-ui-router';

import userSettingsController from './userSettings.controller';
import userAccountsController from './userAccounts.controller';
import userSettingsDialog from './userSettingsDialog.service';

import './userAccounts.scss';

const module = angular.module('vitarum.components.userAccounts', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('userAccounts', {
            title: 'User Accounts',
            url: '/userAccounts',
            templateUrl: './components/userAccounts/userAccounts.html',
            controller: userAccountsController,
            controllerAs: 'vm',
            role: ['admin', 'publisher'],
            ncyBreadcrumb: {
                label: 'User accounts',
                parent: 'home',
            },
        });
    },
]);

module.controller('UserSettingsController', userSettingsController);
module.controller('UserAccountsController', userAccountsController);
module.service('userSettingsDialog', userSettingsDialog);

export default module.name;
