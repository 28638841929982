import moment from 'moment';

export default function period() {
    return function(input, placeHolder, fieldName) {
        // return placeHolder when input is falsy
        if (!input) {
            return placeHolder || undefined;
        }

        // field specified
        if (fieldName) {
            const inputField = input[fieldName];

            // specified field is falsy
            if (!inputField) {
                return placeHolder || undefined;
            }

            if (!inputField.year) {
                return placeHolder || undefined;
            }

            return formatYearMonth(inputField);
        }

        // no year to display
        if (!input.from && !input.until) {
            return placeHolder || undefined;
        }
        if (input.from && !input.from.year && !input.until) {
            return placeHolder || undefined;
        }
        if (input.until && !input.until.year && !input.from) {
            return placeHolder || undefined;
        }
        if (
            input.until &&
            input.from &&
            !input.until.year &&
            !input.from.year
        ) {
            return placeHolder || undefined;
        }

        // single field specified
        if (!input.from || !input.from.year) {
            return formatYearMonth(input.until);
        }
        if (!input.until || !input.until.year) {
            return formatYearMonth(input.from);
        }

        // combined fields
        return (
            `${formatYearMonth(input.from)  } - ${  formatYearMonth(input.until)}`
        );
    };

    function formatYearMonth(input) {
        // year is falsy
        if (!input.year) {
            return undefined;
        }

        // present
        if (input.year === 9998) {
            return 'Present';
        }

        // no month
        if (!input.month) {
            return input.year.toString();
        }

        // invalid month
        if (input.month > 12) {
            return `Invalid month ${  input.year}`;
        }

        // date and month
        const date = moment().month(input.month - 1);
        return `${date.format('MMM')  } ${  input.year}`;
    }
}
