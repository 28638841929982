export default function StatisticsModelFactory($modelFactory) {
    const model = $modelFactory('api/statistics', {
        actions: {
            query: {
                afterRequest: data => {
                    const items = data.data;
                    items.totalViews = data.totalViews;
                    items.totalDownloads = data.totalDownloads;
                    items.totalItems = data.totalItems;
                    return items;
                },
            },
        },
    });

    return model;
}

StatisticsModelFactory.$inject = ['$modelFactory'];
