import template from './editableBook.directive.html';
import './editableBook.directive.scss';
//import moment from 'moment';

/*
 <vt-book   vt-editable-book
 editable="vm.editable"
 onaftersave="vm.saveBlock()"
 ng-model="vm.book"
 </vt-book>
 */

EditableBookDirectiveController.$inject = [
    '$parse',
    '$scope',
    '$element',
    '$document',
    'BookModel',
    '$timeout',
    '$http',
    '$q',
];
function EditableBookDirectiveController(
    $parse,
    $scope,
    $element,
    $document,
    BookModel,
    $timeout,
    $http,
    $q,
) {
    const cvm = this;
    let editable = false;
    let onAfterSafeFn = angular.noop; //empty function
    let bookModel = {};
    let ngModel = {};

    // variables
    cvm.active = false;
    cvm.showHelpMessage = true;
    cvm.showErrorMessage = false;
    cvm.book = null;
    cvm.isbn = null;

    // functions
    cvm.find = find;

    // called from link function to pass ngModel and attributes
    this.init = function(_ngModel_, attrs) {
        ngModel = _ngModel_;
        editable = $parse(attrs.editable)($scope);
        onAfterSafeFn = $parse(attrs.onaftersave);

        if (editable) {
            // click on parent element
            $element.bind('click', clickBind);
        }

        // destroy binds
        $scope.$on('$destroy', () => {
            $element.unbind('click', clickBind);
            $document.unbind('click', documentClickBind);
            $document.unbind('keydown', documentKeydownBind);
        });
    };

    function clickBind() {
        $scope.$apply(() => {
            openPopOver();
            $element.unbind('click', clickBind);
        });
    }

    function openPopOver() {
        if (editable) {
            cvm.active = true;
            bookModel = ngModel.$modelValue;

            // Add event bindings
            $document.bind('keydown', documentKeydownBind);
            $document.bind('click', documentClickBind);
        }
    }

    function documentClickBind(event) {
        // popup was open and we clicked on the document
        const containsTarget = $element[0].contains(event.target);

        if (!containsTarget) {
            // we clicked outside the popup, lets close it
            $scope.$apply(() => {
                closePopOver(true);
            });
        }
    }

    function documentKeydownBind(event) {
        if (event.which === 27) {
            $scope.$apply(() => {
                closePopOver(false);
            });
        }
    }

    function closePopOver(maySave) {
        if (!cvm.active) {
            return;
        }
        cvm.active = false;

        if (maySave) {
            save();
        }

        // need to postpone click bind otherwise the click cancel gets
        // handled as a click on the element to open it again.
        $timeout(() => {
            $element.bind('click', clickBind);
        }, 0);

        $document.unbind('click', documentClickBind);
        $document.unbind('keydown', documentKeydownBind);
    }

    function save() {
        if (!cvm.book) {
            return;
        }

        // set vt-book
        bookModel.title = cvm.book.title;
        bookModel.author = cvm.book.author;
        bookModel.date = cvm.book.date;
        bookModel.coverUrl = cvm.book.coverUrl;
        bookModel.infoUrl = cvm.book.infoUrl;

        // fire save event
        onAfterSafeFn($scope);
    }

    function find() {
        if (!cvm.isbn) {
            cvm.showHelpMessage = false;
            cvm.showErrorMessage = true;
            return;
        }

        const url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${  cvm.isbn}`;
        $http({
            method: 'GET',
            url: url,
            skipAuthorization: true,
        })
            .then(({data}) => {
                if (data.totalItems < 1 || data.items.length < 1)
                    return $q.reject('No data received');

                cvm.showErrorMessage = false;
                cvm.showHelpMessage = false;
                cvm.book = {
                    title: data.items[0].volumeInfo.title,
                    author: data.items[0].volumeInfo.authors[0],
                    date: data.items[0].volumeInfo.publishedDate,
                    dateToDisplay: data.items[0].volumeInfo.publishedDate,
                    coverUrl: data.items[0].volumeInfo.imageLinks.smallThumbnail,
                    infoUrl: data.items[0].volumeInfo.infoLink,
                };
            })
            .catch(() => {
                cvm.book = null;
                cvm.showErrorMessage = true;
                cvm.showHelpMessage = false;
            });
    }
}

export const editableBookDirective = function($compile) {
    return {
        restrict: 'A',
        require: ['?ngModel', 'vtEditableBook'],
        link: function(scope, element, attrs, ctrls) {
            const ngModelCtrl = ctrls[0];
            const editableBookCtrl = ctrls[1];

            editableBookCtrl.init(ngModelCtrl, attrs);

            const popupElement = $compile(template)(scope);
            element.append(popupElement);
        },
        controller: EditableBookDirectiveController,
        controllerAs: 'cvm',
    };
};

editableBookDirective.$inject = ['$compile'];
