import template from './editableCheckbox.directive.html';

export class EditableCheckboxDirectiveController {
    constructor($scope, $log, $document, $element) {
        this.$log = $log;
        this.$scope = $scope;
        this.$document = $document;
        this.$element = $element;
        this.isOpen = false;
    }

    init(ngModel) {
        this.ngModel = ngModel;
    }

    onOpen(editable) {
        if (editable) {
            // toggle popup
            this.isOpen = !this.isOpen;

            // bind to click outside element, so we can cancel when clicked outside
            this.$document.bind('click', event =>
                this.documentClickBind(event)
            );
        }
    }

    documentClickBind(event) {
        if (!this.isOpen) {
            return;
        }

        // popup was open - user clicked on the document
        const containsTarget = this.$element[0].contains(event.target);
        if (!containsTarget) {
            // clicked outside the popup - close it
            this.$scope.$apply(() => {
                this.onCancel();
            });
        }
    }

    onCancel() {
        this.isOpen = false;

        // unbind
        this.$document.unbind('click', event => this.documentClickBind(event));
    }
}

export const editableCheckboxDirective = function() {
    return {
        restrict: 'A',
        template: template,
        transclude: true,
        require: ['?ngModel', 'vtEditableCheckbox'],
        controller: EditableCheckboxDirectiveController,
        controllerAs: 'cvm',
        bindToController: {
            message: '=',
        },
        link: (scope, element, attrs, controllers) => {
            const ngModelCtrl = controllers[0];
            const myCtrl = controllers[1];

            myCtrl.init(ngModelCtrl);
        },
    };
};

EditableCheckboxDirectiveController.$inject = [
    '$scope',
    '$log',
    '$document',
    '$element',
];
