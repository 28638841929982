import userSettingsController from './userSettings.controller';

export default class UserSettingsDialog {
    constructor($rootScope, ngDialog, $q) {
        this.ngDialog = ngDialog;
        this.$q = $q;
        this.$rootScope = $rootScope;
    }

    openDialog(user) {
        const newScope = this.$rootScope.$new();
        newScope.user = user;

        const dialog = this.ngDialog.open({
            template: './components/userAccounts/userSettings.html',
            controller: userSettingsController,
            controllerAs: 'vm',
            scope: newScope,
            className: 'vt-dialog',
        });

        return dialog.closePromise;
    }
}

UserSettingsDialog.$inject = ['$rootScope', 'ngDialog', '$q'];
