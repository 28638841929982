/* eslint-disable no-restricted-globals */
import { debounce } from 'underscore';
import clipboard from 'clipboard-js';

export const tagInputEventsDirective = function($parse) {
    return {
        restrict: 'A',
        compile: () => ({
                post: function(scope, element, attrs) {
                    const onAfterBlur = $parse(attrs.onafterblur);
                    const tags = $parse(attrs.tags)(scope);
                    const button = element.next();
                    let canCopy = false;
                    let pressedCtrl = false;
                    let pressedC = false;
                    const placeholder = element[0].placeholder;

                    button.on('click', function() {
                        clipboard.copy(JSON.stringify(tags));
                    });

                    element.on('blur', debounce(onBlur, 0));
                    element.on('click', debounce(onClick, 0));
                    element.on('focus', debounce(onClick, 0));
                    element.on('keydown', onKeyDown);

                    function onBlur() {
                        if (element.html() === '') {
                            resetToPlaceholder();
                        }

                        if (!element.hasClass('ng-invalid')) {
                            onAfterBlur(scope);
                        }
                    }

                    function onClick() {
                        removePlaceholder();
                        canCopy = true;
                    }

                    function onKeyDown(e) {
                        removePlaceholder();
                        if (canCopy) {
                            if (e.keyCode === 17 || e.keyCode === 91) {
                                pressedCtrl = true;
                            }

                            if (e.keyCode === 67) {
                                pressedC = true;
                            }

                            if (pressedC && pressedCtrl) {
                                // eslint-disable-next-line no-restricted-globals
                                // eslint-disable-next-line no-undef
                                event.preventDefault();
                                button.triggerHandler('click');
                                // eslint-disable-next-line no-multi-assign
                                pressedC = pressedCtrl = false;
                            }
                        }
                    }

                    function removePlaceholder() {
                        element[0].placeholder = '';
                    }

                    function resetToPlaceholder() {
                        element[0].placeholder = placeholder;
                    }
                },
            }),
    };
};

tagInputEventsDirective.$inject = ['$parse'];
