export default class TemplatesController {
    constructor(
        $state,
        template,
        $templateCache,
        TemplateModel,
        confirmationDialog
    ) {
        this.$state = $state;
        this.template = template;
        this.activeTab = 'html';
        this.$templateCache = $templateCache;
        this.title = 'Edit template';
        this.TemplateModel = TemplateModel;
        this.confirmationDialog = confirmationDialog;

        if (!this.template) {
            this.$state.go('templateOverview');
        }

        this.aceOptionsHtml = {
            theme: 'sqlserver',
            shouldDisable: this.template.state === 'locked',
            advanced: {
                enableSnippets: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
            },
            onLoad: this.aceLoaded,
        };

        this.aceOptionsCss = angular.copy(this.aceOptionsHtml);

        this.aceOptionsHtml.mode = 'html';
        this.aceOptionsCss.mode = 'css';
    }

    aceLoaded(editor) {
        editor.setReadOnly(this.shouldDisable);
        const session = editor.getSession();
        session.setUseWrapMode(true);
    }

    saveData() {
        this.template.$saveTemplate().then(() => {
            this.$templateCache.remove(
                `/api/templates/html/${this.template.id}`
            );
            this.$state.go('templateOverview');
        });
    }
    deleteData() {
        return this.confirmationDialog
            .openDialog('Are your sure you want to delete this template?')
            .then((res) => {
                if (res.value === 'yes') {
                    return this.TemplateModel.remove(this.template.id).then(
                        () => {
                            this.$state.go('templateOverview');
                        }
                    );
                }
            });
    }

    cancel() {
        this.$state.go('templateOverview');
    }

    switchTab(tab) {
        this.activeTab = tab;
    }
}

TemplatesController.$inject = [
    '$state',
    'template',
    '$templateCache',
    'TemplateModel',
    'confirmationDialog',
];
