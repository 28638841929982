import angular from 'angular';
//import testTemplate from './testTemplate';
//import defaultTemplate from './defaultTemplate';

const module = angular.module('vitarum.templates', [
    // testTemplate,
    // defaultTemplate,
]);

export default module.name;
