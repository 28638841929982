import angular from 'angular';
import uiRouter from 'angular-ui-router';
import resumesController from './resumes.controller';

import openSelectProjectController from '../resumes/openSelectProjectController.controller';
import openSelectProjectDialog from '../resumes/openSelectProjectDialog.service';
import './resumes.scss';
import './download.scss';

const module = angular.module('vitarum.components.resumes', [uiRouter]);
module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider
            .state('public', {
                context: {
                    isPublic: true,
                    isDownload: false,
                    isEdit: false,
                    isPreview: true,
                },
                url: '/resumes/public/:userName/:id',
                title: 'Resume',
                templateUrl: './components/resumes/resumes.html',
                controller: resumesController,
                controllerAs: 'vm',
                resolve: {
                    resume: [
                        'ResumeModel',
                        '$stateParams',
                        '$log',
                        '$state',
                        function(ResumeModel, $stateParams, $log, $state) {
                            return loadData(
                                ResumeModel,
                                $stateParams.id,
                                $log,
                                $state,
                                true /* published */,
                                false /*download*/
                            );
                        },
                    ],
                },
                ncyBreadcrumb: {
                    label: '{{vm.resume.userName}}',
                    parent: 'home',
                },
            })
            .state('preview', {
                context: {
                    isPublic: false,
                    isDownload: false,
                    isEdit: false,
                    isPreview: true,
                },
                url: '/resumes/preview/:id',
                title: 'Preview',
                templateUrl: './components/resumes/resumes.html',
                controller: resumesController,
                controllerAs: 'vm',
                resolve: {
                    resume: [
                        'ResumeModel',
                        '$stateParams',
                        '$log',
                        '$state',
                        function(ResumeModel, $stateParams, $log, $state) {
                            return loadData(
                                ResumeModel,
                                $stateParams.id,
                                $log,
                                $state,
                                false /* published */,
                                false /*download*/
                            );
                        },
                    ],
                },
                ncyBreadcrumb: {
                    label: 'Preview',
                    parent: 'edit',
                },
            })
            .state('view', {
                context: {
                    isPublic: false,
                    isDownload: false,
                    isEdit: false,
                    isPreview: true,
                },
                url: '/resumes/view/:id',
                title: 'View',
                templateUrl: './components/resumes/resumes.html',
                controller: resumesController,
                controllerAs: 'vm',
                resolve: {
                    resume: [
                        'ResumeModel',
                        '$stateParams',
                        '$log',
                        '$state',
                        function(ResumeModel, $stateParams, $log, $state) {
                            return loadData(
                                ResumeModel,
                                $stateParams.id,
                                $log,
                                $state,
                                false /* published */,
                                false /*download*/
                            );
                        },
                    ],
                },
                ncyBreadcrumb: {
                    label: '{{vm.resume.userName}}',
                    parent: 'home',
                },
            })
            .state('download', {
                context: {
                    isPublic: false,
                    isDownload: true,
                    isEdit: false,
                    isPreview: false,
                },
                url: '/resumes/download/:id',
                title: 'Download',
                templateUrl: './components/resumes/download.html',
                controller: resumesController,
                controllerAs: 'vm',
                resolve: {
                    resume: [
                        'ResumeModel',
                        '$stateParams',
                        '$log',
                        '$state',
                        function(ResumeModel, $stateParams, $log, $state) {
                            return loadData(
                                ResumeModel,
                                $stateParams.id,
                                $log,
                                $state,
                                false /* published */,
                                true /*download*/
                            );
                        },
                    ],
                },
            })
            .state('edit', {
                context: {
                    isPublic: false,
                    isDownload: false,
                    isEdit: true,
                    isPreview: false,
                },
                url: '/resumes/:id',
                title: 'Resume',
                templateUrl: './components/resumes/resumes.html',
                controller: resumesController,
                controllerAs: 'vm',
                resolve: {
                    resume: [
                        'ResumeModel',
                        '$stateParams',
                        '$log',
                        '$state',
                        '$rootScope',
                        function(
                            ResumeModel,
                            $stateParams,
                            $log,
                            $state,
                            $rootScope
                        ) {
                            return loadData(
                                ResumeModel,
                                $stateParams.id,
                                $log,
                                $state,
                                false /* published */,
                                false /*download*/,
                                true /*edit*/,
                                $rootScope
                            );
                        },
                    ],
                },
                ncyBreadcrumb: {
                    label: '{{vm.resume.name}}',
                    parent: 'home',
                },
            });

        function loadData(
            ResumeModel,
            resumeId,
            $log,
            $state,
            published,
            download,
            edit,
            $rootScope
        ) {
            return ResumeModel.getWithTemplate(
                resumeId,
                published,
                download,
                edit
            )
                .then(resume => {
                    if (
                        (edit && resume.state === 'public') ||
                        resume.state === 'archived' ||
                        ($rootScope &&
                            resume.userId !== $rootScope.identity.profile.id &&
                            $rootScope.identity.profile.role === 'editor')
                    ) {
                        $state.go('home');
                    }

                    return resume;
                })
                .catch(response => {
                    if (response.status === 404) {
                        $state.go('notfound');
                    }
                    if (response.status === 403) {
                        $state.go('forbidden');
                    }
                    if (response.status === 401) {
                        $state.go('login');
                    }
                    //return undefined;
                });
        }
    },
]);

module.controller('ResumesController', resumesController);
module.service('openSelectProjectDialog', openSelectProjectDialog);
module.controller('openSelectProjectController', openSelectProjectController);

export default module.name;
