export default function UserModelFactory($modelFactory) {
    const model = $modelFactory('api/users', {
        // options
        defaults: {
            role: 'editor',
            picture: '../../assets/avatar_placeholder.png',
        },
        actions: {
            query: {
                afterRequest: data => data.users,
            },
            getByEmail: {
                method: 'GET',
                url: 'byEmail/{email}',
                // overrides the root url
                override: false,
            },
        },
    });

    return model;
}

UserModelFactory.$inject = ['$modelFactory'];
