import { map } from 'underscore';

export default class ProjectCreateController {
    constructor(
        $log,
        $scope,
        $http,
        MediaModel,
        $rootScope,
        TagModel,
        ProjectModel,
        $location,
        confirmationDialog,
    ) {
        this.$log = $log;
        this.$scope = $scope;
        this.$http = $http;
        this.title = 'New project';
        this.roles = [];
        this.MediaModel = MediaModel;
        this.$rootScope = $rootScope;
        this.TagModel = TagModel;
        this.ProjectModel = ProjectModel;
        this.project = $scope.project || new this.ProjectModel();
        this.$location = $location;
        this.confirmationDialog = confirmationDialog;
        this.tempFile = null;
        this.isUploading = false;
        this.currentUser = this.$rootScope.identity.profile;

        this.roles = ['admin', 'editor', 'publisher'];
        this.$scope.getAutocompleteResults = (query, deferred) => {
            this.loadTags(query).then(items => {
                const data = { results: [] };
                data.results = map(items, item => ({ value: item.text }));

                deferred.resolve(data);
            });
        };
    }

    loadTags(query) {
        return this.TagModel.query({ filter: query }).then(function(items) {
            const data = map(items, function(item) {
                return {
                    text: item.name,
                };
            });

            return data;
        });
    }

    saveData(valid) {
        if (!valid) return;
        this.isUploading = true;
        return this.project
            .$save()
            .then(() => {
                this.isUploading = false;

                this.$location.path('projects');
            })
            .catch(() => {
                this.isUploading = false;
            });
    }
    deleteData() {
        return this.confirmationDialog
            .openDialog(
                `Are you sure you want to delete ${this.project.title}?`
            )
            .then(res => {
                if (res.value === 'yes') {
                    this.project.$destroy().then(() => {
                        this.$location.path('projects');
                    });
                }
            });
    }

    cancel() {
        this.$location.path( "/projects");
    }
}

ProjectCreateController.$inject = [
    '$log',
    '$scope',
    '$http',
    'MediaModel',
    '$rootScope',
    'TagModel',
    'ProjectModel',
    '$location',
    'confirmationDialog',
];
