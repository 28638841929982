import { map,  each,  uniq, find } from 'underscore';

export default class SearchSkillsController {
    constructor(
        $log,
        UserModel,
        MediaModel,
        ResumeModel,
        userSettingsDialog,
        confirmationDialog,
        $rootScope,
        store,
        $scope
    ) {
        this.$log = $log;
        this.UserModel = UserModel;
        this.userSettingsDialog = userSettingsDialog;
        this.confirmationDialog = confirmationDialog;
        this.users = [];
        this.foundUsers = [];
        this.resumesSkills = [];
        this.MediaModel = MediaModel;
        this.$rootScope = $rootScope;
        this.store = store;
        this.$scope = $scope;

        ResumeModel.getResumeWithTags().then(resumesSkills => {
            this.resumesSkills = resumesSkills;
        });

        UserModel.query().then(users => {
            this.users = users;
            this.foundUsers = this.users;
        });
    }

    filterUsers(userFilter) {
        if (userFilter) {
            const filterTags = userFilter.split(' ');
            let userIds = [];

            map(this.resumesSkills, function(resumesSkill) {
                map(resumesSkill.searchSkills, function(skill) {
                    for (let i = 0; i < filterTags.length; i++) {
                        if (skill.text.includes(filterTags[i].toLowerCase())) {
                            userIds.push(resumesSkill.user_id);
                        }
                    }
                });
            });

            userIds = uniq(userIds);
            this.foundUsers = [];

            each(userIds, userId => {
                const foundUser = find(this.users, function(user) {
                    return user.id === userId;
                });
                if (foundUser) {
                    this.foundUsers.push(foundUser);
                }
            });
        } else {
            this.foundUsers = this.users;
        }
    }
}

SearchSkillsController.$inject = [
    '$log',
    'UserModel',
    'MediaModel',
    'ResumeModel',
    'userSettingsDialog',
    'confirmationDialog',
    '$rootScope',
    'store',
    '$scope',
];
