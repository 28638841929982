import angular from 'angular';
import uiRouter from 'angular-ui-router';

import tagsLibraryController from './tagsLibrary.controller';
import tagSettingsController from './tagSettings.controller';
import tagSettingsDialog from './tagsSettingsDialog.service';

import './tagsLibrary.scss';

const module = angular.module('vitarum.components.tagsLibrary', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('skill', {
            title: 'Skills Library',
            url: '/skills',
            templateUrl: './components/tagsLibrary/tagsLibrary.html',
            controller: tagsLibraryController,
            controllerAs: 'vm',
            role: ['admin', 'publisher'],
            ncyBreadcrumb: {
                label: 'Skills',
                parent: 'home',
            },
        });
    },
]);

module.controller('TagsLibraryController', tagsLibraryController);
module.controller('TagSettingsController', tagSettingsController);
module.service('tagSettingsDialog', tagSettingsDialog);

export default module.name;
