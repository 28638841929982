import angular from 'angular';
import uiRouter from 'angular-ui-router';

import homeController from './home.controller';
import { resumeInfoDirective } from './resumeInfo.directive';

import './home.scss';

const module = angular.module('vitarum.components.home', [uiRouter]);

module.config([
    '$stateProvider',
    $stateProvider => {
        $stateProvider.state('home', {
            url: '/',
            title: 'Resumes',
            templateUrl: './components/home/home.html',
            controller: homeController,
            controllerAs: 'vm',
            role: ['admin', 'publisher', 'editor'],
            ncyBreadcrumb: {
                label: 'Vitarum',
                skip: true,
            },
        });
    },
]);

module.controller('HomeController', homeController);
module.directive('resumeInfo', resumeInfoDirective);

export default module.name;
