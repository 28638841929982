/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-rest-params */
import angular from 'angular';

angular
    .module('vitarum')
    .config(auth0)
    .config(breadcrumbs)
    .config(routing)
    .config(interceptors)
    .config(errorHandler)
    .config(extendDatePicker)
    .run(registerErrorToast);

breadcrumbs.$inject = ['$breadcrumbProvider'];
function breadcrumbs($breadcrumbProvider) {
    $breadcrumbProvider.setOptions({
        template: `<div class="ko-breadcrumbs">
                <a class="ko-breadcrumbs__item" href="#/" ng-if="$root.identity.profile.role">Vitarum</a>
                <p class="ko-breadcrumbs__item non-clickable" ng-if="!$root.identity.profile.role">Vitarum</p>
                <a href="{{step.ncyBreadcrumbLink}}" class="ko-breadcrumbs__item breadcrumb-{{key}}" ng-repeat="(key, step) in steps">
                    {{step.ncyBreadcrumbLabel | limitTo: 30}}{{step.ncyBreadcrumbLabel.length > 30 ? '...' : ''}}</a>
            </div>`,
    });
}

// routing.$inject = ['$urlRouterProvider'];
// function routing($urlRouterProvider) {
//     $urlRouterProvider.otherwise('/');
// }

routing.$inject = ['$stateProvider'];
function routing($stateProvider) {
    $stateProvider.state('otherwise', {
        url: '*path',
        template: '',
        controller: [
            '$state',
            function($state) {
                $state.go('home');
            },
        ],
    });
}

auth0.$inject = ['lockProvider'];
function auth0(lockProvider) {
    lockProvider.init({
        domain: 'euri.eu.auth0.com',
        clientID: 'trKUlWTUIgmCX4bTOvfQlPSK86YZlaDf',
        options: {
            _idTokenVerification: true,
            autoclose: true,
            auth: {
                params: { scope: 'openid email' },
            },
        },
    });
}

interceptors.$inject = ['$httpProvider', 'jwtInterceptorProvider'];
function interceptors($httpProvider, jwtInterceptorProvider) {
    jwtInterceptorProvider.tokenGetter = [
        'store',
        store => store.get('id_token'),
    ];
    $httpProvider.interceptors.push('httpErrorInterceptor');
    $httpProvider.interceptors.push('jwtInterceptor');
}

registerErrorToast.$inject = ['$rootScope', 'toaster', 'events'];
function registerErrorToast($rootScope, toaster, events) {
    $rootScope.$on(events.errorEvent, (event, title, detail) => {
        toaster.pop({
            type: 'error',
            title: title,
            body: detail,
        });
    });
}

errorHandler.$inject = ['$provide'];
function errorHandler($provide) {
    $provide.decorator('$exceptionHandler', [
        '$delegate',
        '$injector',
        'events',
        function($delegate, $injector, events) {
            return function(exception, cause) {
                const scope = $injector.get('$rootScope');
                const message = exception && exception.message;
                scope.$emit(events.errorEvent, 'Program Error', message);
                $delegate(exception, cause);
            };
        },
    ]);
}

//workaround automatisch sluiten hourpickers book interview focus
extendDatePicker.$inject = ['$provide'];
function extendDatePicker($provide) {
    $provide.decorator('dateTimeDirective', [
        '$delegate',
        function($delegate) {
            const directive = $delegate[0];
            const link = directive.link;

            directive.compile = function() {
                return function(scope, element, attrs) {
                    element.triggerHandler('blur');
                    link.apply(this, arguments);
                    element.bind('click', function() {
                        scope.$on('setDate', function(event, date, view) {
                            const test = document.getElementById(
                                'contactPhone'
                            );
                            if (test) test.focus();
                        });
                    });
                };
            };

            return $delegate;
        },
    ]);
}
