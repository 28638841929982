export default class ProjectsController {
    constructor(
        $log,
        ProjectModel,
        store,
        projectSettingsDialog,
        confirmationDialog,
        $q,
        $rootScope,
        $location
    ) {
        this.$log = $log;
        this.ProjectModel = ProjectModel;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.projects = [];
        this.store = store;
        this.loading = true;
        this.projectSettingsDialog = projectSettingsDialog;
        this.confirmationDialog = confirmationDialog;
        this.$q = $q;

        this.ProjectModel.query().then(projects => {
            this.projects = projects;
            this.loading = false;
        });
    }


    searchProjects(){
        this.filterProjects(this.searchText);
    }
    filterProjects(searchText="") {
        this.ProjectModel.query({ filter: searchText }).then(projects => {
            this.projects = projects;
        });
    }

    openSettings(project) {
                if(project){
                    this.$location.path(`projects/edit/${project.id}`)

                }else{
                    this.$location.path('projects/create')
                }

    }
}

ProjectsController.$inject = [
    '$log',
    'ProjectModel',
    'store',
    'projectSettingsDialog',
    'confirmationDialog',
    '$q',
    '$rootScope',
    '$location'
];
