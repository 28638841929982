import template from './skillCircle.directive.html';
import './skillCircle.directive.scss';

/*
    <vt-skill-circle title="skill.title" level-value="skill.level" levels="vm.languageLevels" level-max-value="5">
    <vt-skill-circle title="English" level="5">
    <vt-skill-circle title="French" level="1">
    <vt-skill-circle title="French">
    <vt-skill-circle>
 */

class SkillCircleDirectiveController {
    constructor($scope, $animateCss, $element) {
        this.levelValue = this.levelValue || '0';
        this.levelMaxValue = this.levelMaxValue || '4';

        $scope.$watch('vm.levelValue', value => {
            if (!value) return;
            this.levelPercentage =
                parseInt(this.levelValue, 10) *
                (100 / parseInt(this.levelMaxValue, 10));
        });

        $animateCss($element, {
            event: 'enter',
            structural: true,
        }).start();
    }
}

export const skillCircleDirective = function() {
    return {
        restrict: 'E',
        template: template,
        scope: {},
        controller: SkillCircleDirectiveController,
        controllerAs: 'vm',
        bindToController: {
            title: '@',
            levels: '=',
            levelValue: '@',
            levelMaxValue: '@',
        },
        transclude: true,
    };
};

SkillCircleDirectiveController.$inject = ['$scope', '$animateCss', '$element'];
