export class Identity {
    constructor(name, email, profile) {
        this.name = 'anonymous';
        this.email = null; // not authenticated
        this.profile = {};
        if (email) {
            this.name = name;
            this.email = email;
            this.profile = profile;
            this.role = profile.role;
        }
    }
    isAuthenticated() {
        return !!this.email;
    }

    isInRole(roles) {
        const role = roles.find(item => item === this.role);
        return role;
    }
    canIAuthor(resume) {
        if (!this.profile) {
            return false;
        }
        if (
            !resume ||
            resume.userId === this.profile.id ||
            this.profile.role !== 'editor'
        ) {
            return true;
        }
        return false;
    }
}

export default class AuthService {
    constructor($rootScope, lock, store, UserModel, $state, $log, $location) {
        this.$rootScope = $rootScope;
        this.lock = lock;
        this.store = store;
        this.$state = $state;
        this.$log = $log;
        this.$location = $location;
        this.UserModel = UserModel;
    }

    init() {
        this.$rootScope.identity = new Identity(); // anonymous

        // For use with UI Router
        this.lock.interceptHash();

        const idIdentity = this.store.get('id-identity');
        if (idIdentity)
            this.$rootScope.identity = new Identity(idIdentity.name, idIdentity.email, idIdentity.profile);

        this.lock.on(
            'authenticated',
            function(authResult) {
                this.$log.info('authenticated', authResult);
                this.store.set('id_token', authResult.idToken);

                this.UserModel
                    .getByEmail({
                        email: authResult.idTokenPayload.email,
                    })
                    .then(user => {
                        // eslint-disable-next-line no-console
                        console.log('OK', user);
                        if (!user.authEmails) {
                            return this.$log.error('User not in DB');
                        }
                        this.$rootScope.identity = new Identity(
                            user.name,
                            user.email,
                            user
                        );
                        this.store.set('id-identity', this.$rootScope.identity);
                        this.$state.go('home');
                    })
                    .catch(err => {
                        // eslint-disable-next-line no-console
                        console.log('ERROR', err);
                        this.$log.warn('err', err);
                    });
            }.bind(this)
        );

        this.lock.on(
            'authorization_error',
            function(error) {
                this.$rootScope.$emit('LOGIN-FAILED');
                this.$rootScope.authFailed = true;
                this.$log.warn('Failed to login: ', error);
            }.bind(this)
        );
    }

    authenticate() {
        this.lock.show();
    }

    logout() {
        const returnTo = `${this.$location.protocol()}://${this.$location.host()}:${this.$location.port()}`;
        this.lock.logout({
            returnTo,
        });
        this.store.remove('id-identity');
        this.store.remove('id_token');
    }
}

AuthService.$inject = [
    '$rootScope',
    'lock',
    'store',
    'UserModel',
    '$state',
    '$log',
    '$location',
];
