import {  map,  } from 'underscore';

export default class ProjectSettingsController {
    constructor(
        $log,
        $scope,
        $http,
        ngDialog,
        MediaModel,
        $rootScope,
        TagModel
    ) {
        this.$log = $log;
        this.$scope = $scope;
        this.$http = $http;
        this.ngDialog = ngDialog;
        this.project = $scope.project;
        this.title = 'Edit project';
        this.roles = [];
        this.MediaModel = MediaModel;
        this.$rootScope = $rootScope;
        this.TagModel = TagModel;

        // this.projectName = this.project.name;
        // this.projectRole = this.project.role;
        // this.projectPicture = this.project.picture;
        this.tempFile = null;
        this.isUploading = false;
        this.currentUser = this.$rootScope.identity.profile;

        if (!this.project.id) {
            this.title = 'Add new project';
        }

        this.roles = ['admin', 'editor', 'publisher'];
        this.$scope.getAutocompleteResults = (query, deferred) => {
            this.loadTags(query).then(items => {
                const data = { results: [] };
                data.results = map(items, item => ({ value: item.text }));

                deferred.resolve(data);
            });
        };
    }

    loadTags(query) {
        return this.TagModel.query({ filter: query }).then(function(items) {
            const data = map(items, function(item) {
                return {
                    text: item.name,
                };
            });

            return data;
        });
    }

    saveData(valid) {
        if (!valid) return;
        this.ngDialog.close(1, 'saveSettings');
    }

    deleteData() {
        this.ngDialog.close(1, 'deleteProject');
    }

    cancel() {
        this.ngDialog.close(1, 'cancel');
    }

    convertToLowerCase(emails) {
        const lowercased = [];

        for (let i = 0; i < emails.length; i++) {
            lowercased[i] = emails[i].toLowerCase();
        }
        return lowercased;
    }
}


ProjectSettingsController.$inject = [
    '$log',
    '$scope',
    '$http',
    'ngDialog',
    'MediaModel',
    '$rootScope',
    'TagModel',
];
