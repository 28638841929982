/*

 Example :

 <article
 vt-editable-textarea
 ng-model='vm.summary'
 onaftersave="vm.updateResume()"
 editable="vm.editable"
 >
 Type your text here..
 </article>

 */
import MediumEditor from 'medium-editor';
import toMarkdown from 'to-markdown';
import marked from 'marked';
import './../_editableContent.scss';

marked.setOptions({
    breaks: true,
    sanitize: false,
});

/*eslint-disable */

export const editableTextareaDirective = function() {
    return {
        restrict: 'A',
        scope: {
            editable: '=',
            onaftersave: '&',
            beforeedit: '&',
            removebackup: '&',
        },
        require: '?ngModel',
        link: function(scope, element, attrs, ngModel) {
            let placeholder = 'Type your text here.. HTML is allowed';
            let editor;
            let oldValue = '';
            let editable = scope.editable;
            let backup = false;

            activate();

            //////////////////

            function activate() {
                // don't do anything unless this is actually bound to a model
                if (!ngModel) {
                    return;
                }

                if (editable && typeof editable !== 'undefined') {
                    element.addClass('content-editable');
                    element.attr('tabindex', '0');
                }

                // Set custom placeholder
                setCustomPlaceholder(element.html());

                // If empty set placeholder
                if (stripHTML(ngModel.$modelValue) === '') {
                    resetToPlaceholder();
                }

                //// Event bindings
                element.on('click', onClick);
                element.on('keydown', onKeyDown);
                element.on('focus', onFocus);

                // model to view
                ngModel.$formatters.push(function(value) {
                    if (value) {
                        // md to html
                        return marked(value);
                    }

                    return null;
                });

                // view to model
                ngModel.$parsers.unshift(function(value) {
                    if (value === placeholder) {
                        return null;
                    }

                    return toMarkdown(value, {
                        converters: [
                            {
                                filter: 'br',
                                replacement: () => {
                                    return '<br />';
                                },
                            },
                        ],
                    });
                });

                scope.$on('$destroy', cleanUp);
                ngModel.$render = render;
            }

            function cleanUp() {
                element.off('keydown');
                element.off('click');
                element.off('focus');
            }

            function render() {
                if (
                    (ngModel.$modelValue === null || !ngModel.$modelValue) &&
                    editable
                ) {
                    resetToPlaceholder();
                }

                element.html(ngModel.$viewValue);
            }

            function save() {
                scope.$apply(read);

                // is value changed
                if (oldValue !== ngModel.$viewValue) {
                    if (ngModel.$viewValue === placeholder) {
                        ngModel.$setViewValue('');
                    }
                    scope.onaftersave();
                }

                backup = false;

                //console.log('remove wysiwyg');
                // remove editor
                editor.destroy();
                element.removeClass('editable-wysiwyg');
                editor = null;
            }

            function onClick() {
                //console.log('click');

                if (!backup) {
                    backup = true;
                    scope.beforeedit();
                }

                element.editable = true;
                element.addClass('editable-wysiwyg');

                if (typeof editable !== 'undefined') {
                    element.editable = editable;
                }

                if (!editor && element.editable) {
                    editor = new MediumEditor('.editable-wysiwyg', {
                        placeholder: false,
                        toolbar: {
                            allowMultiParagraphSelection: true,
                            buttons: [
                                'bold',
                                'italic',
                                'underline',
                                'anchor',
                                'unorderedlist',
                            ],
                        },
                        paste: {
                            forcePlainText: true,
                            cleanPastedHTML: false,
                        },
                    });

                    editor.subscribe('blur', save);
                    editor.subscribe('editableKeydownTab', save);
                }

                element[0].focus();
            }

            function onKeyDown(e) {
                if (e.keyCode === 27) {
                    ngModel.$render();
                    element[0].blur();
                }
            }

            function onFocus() {
                //console.log('focus');

                if (!element.hasClass('editable-wysiwyg')) {
                    //console.log('trigger click');
                    onClick();
                }

                if (stripHTML(element.html()) === stripHTML(placeholder)) {
                    element.html('');
                }

                oldValue = ngModel.$viewValue;
            }

            function stripHTML(data) {
                const html = data;
                const div = document.createElement('div');
                div.innerHTML = html;
                let text = div.textContent || div.innerText || '';
                text = text.replace(/<\/?[^>]+(>|$)/g, '');
                //text = text.replace(/\r?\n|\r/g, ''); // line breaks
                text = text.replace(
                    /(?:\/\*(?:[\s\S]*?)\*\/)|(?:([\s;])+\/\/(?:.*)$)/gm,
                    ''
                ); // comments
                text = text.replace(/^\s+|\s+$/g, ''); // leading and trailing spaces

                return text;
            }

            function resetToPlaceholder() {
                ngModel.$setViewValue(placeholder);
                element.html(placeholder);
            }

            function setCustomPlaceholder(html) {
                if (stripHTML(html) !== '') {
                    placeholder = html;
                }
            }

            function read() {
                const html = element.html();
                const striped = stripHTML(html);

                if (striped === '') {
                    resetToPlaceholder();
                } else {
                    ngModel.$setViewValue(html);
                }
            }
        },
    };
};

/*eslint-enable */
