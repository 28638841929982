export default class StatisticsController {
    constructor($log, $scope, StatisticsModel, $rootScope) {
        this.$log = $log;
        this.$scope = $scope;
        this.StatisticsModel = StatisticsModel;
        this.limit = 50;
        this.$rootScope = $rootScope;
        this.$scope.statsFilter = '';
        this.totalViews = 0;
        this.totalDownloads = 0;
        this.page = 0;
        this.totalStats = 0;

        this.$scope.$watch('statsFilter', newValue => {
            this.filterStats(newValue);
        });

        if (
            this.$rootScope.identity.profile.statsFilter &&
            this.$rootScope.identity.profile.statsFilter !== 'undefined'
        ) {
            this.$scope.statsFilter = this.$rootScope.identity.profile.statsFilter;
        }
    }

    loadMore() {
        this.page += 1;
        this.StatisticsModel
            .query({
                filter: this.$scope.statsFilter,
                page: this.page,
                pageSize: this.limit,
            })
            .then(stats => {
                angular.forEach(stats, item => {
                    this.stats.push(item);
                });
            });
    }

    filterStats(filter) {
        this.page = 0;
        this.limit = 50;
        this.$rootScope.identity.profile.statsFilter = filter;
        this.StatisticsModel
            .query({ filter: filter, page: this.page, pageSize: this.limit })
            .then(stats => {
                this.stats = stats;
                this.totalDownloads = stats.totalDownloads;
                this.totalViews = stats.totalViews;
                this.totalStats = stats.totalItems;
            });
    }
}

StatisticsController.$inject = [
    '$log',
    '$scope',
    'StatisticsModel',
    '$rootScope',
];
