import template from './imagePicker.directive.html';
import './imagePicker.directive.scss';

export class ImagePickerDirectiveController {
    constructor($scope, $log, $document, $element, MediaModel) {
        this.$log = $log;
        this.$scope = $scope;
        this.$document = $document;
        this.$element = $element;
        this.isOpen = false;
        this.MediaModel = MediaModel;
        this.images = {};
        this.isPlaceholder = false;

        this.MediaModel.query().then(data => {
            this.images = data;
        });
    }

    init(ngModel) {
        this.ngModel = ngModel;

        // model to view
        ngModel.$formatters.push(value => {
            if (!value) {
                if (this.editable) {
                    this.isPlaceholder = true;
                }
                return null;
            }
            return value;
        });

        // view to model
        ngModel.$parsers.push(value => {
            if (ngModel.$viewValue) {
                return value;
            }
            return null;
        });

        ngModel.$render();
    }

    imageSelect(image) {
        this.isPlaceholder = false;
        this.ngModel.$setViewValue(image.fileName);
        this.ngModel.$render();
    }

    onOpen() {
        this.isOpen = true;
        // bind to click outside element, so we can cancel when clicked outside
        this.$document.bind('click', event => this.documentClickBind(event));

        // bind to key down to capture ESC, to close the popup
        this.$document.bind('keydown', event =>
            this.documentKeydownBind(event)
        );
    }

    resetImage() {
        this.ngModel.$setViewValue(null);
        this.isPlaceholder = true;
        this.onaftersave();
    }

    onCancel() {
        // close popup
        this.isOpen = false;

        // unbind
        this.$document.unbind('click', event => this.documentClickBind(event));
        this.$document.unbind('keydown', event =>
            this.documentKeydownBind(event)
        );

        // Save
        this.onaftersave();
    }

    documentClickBind(event) {
        if (!this.isOpen) {
            return;
        }

        // popup was open and we clicked on the document
        const containsTarget = this.$element[0].contains(event.target);
        if (!containsTarget) {
            // we clicked outside the popup, lets close it
            this.$scope.$apply(() => {
                this.onCancel();
            });
        }
    }

    documentKeydownBind(event) {
        if (event.which === 27) {
            this.$scope.$apply(() => {
                this.onCancel();
            });
        }
    }
}

export const imagePickerDirective = function() {
    return {
        restrict: 'A',
        require: ['?ngModel', 'vtImagePicker'],
        template: template,
        scope: {},
        controller: ImagePickerDirectiveController,
        controllerAs: 'ivm',
        bindToController: {
            onaftersave: '&',
            editable: '=',
        },
        transclude: true,
        link: (scope, element, attrs, controllers) => {
            const ngModelCtrl = controllers[0];
            const myCtrl = controllers[1];
            myCtrl.init(ngModelCtrl);
        },
    };
};

ImagePickerDirectiveController.$inject = [
    '$scope',
    '$log',
    '$document',
    '$element',
    'MediaModel',
];
