export default function urlSubstring() {
    return function(input) {
        if (input === null) {
            return null;
        }
        if (!input) {
            return undefined;
        }

        if (input.search('https://www.') !== -1) {
            return input.substring(12);
        } else if (input.search('http://www.') !== -1) {
            return input.substring(11);
        } else if (input.search('https://') !== -1) {
            return input.substring(8);
        } else if (input.search('http://') !== -1) {
            return input.substring(7);
        }

        return input;
    };
}
