import moment from 'moment';

TemplateModelFactory.$inject = ['$modelFactory'];

export default function TemplateModelFactory($modelFactory) {
    const model = $modelFactory('api/templates', {
        defaults: {

        },
        actions: {
            query: {
                afterRequest: data => data.templates,
            },
            $put: {
                method: 'PUT',
                url: '{id}',
                override: false,
            }
        },
        instance: {
            friendlyDate() {
                return moment(this.updated).fromNow();
            },
            $saveTemplate() {
                if (this.id) {
                    return this.$put();
                }

                return this.$save();
            },
        },
        duplicate(id) {
            return model.$call({
                method: 'POST',
                url: `api/templates/${id}/duplicate`
            });
        },

        new() {
            return model.$call({
                method: 'POST',
                url: `api/templates`
            })
        },

        remove(id) {
            return model.$call({
                method: 'DELETE',
                url: `api/templates/${id}`
            });
        }
    });

    return model;
}

TemplateModelFactory.$inject = ['$modelFactory'];
