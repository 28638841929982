import template from './popup.directive.html';

class PopupDirectiveController {
    constructor($scope, $element, $document) {
        this.$scope = $scope;
        this.showPopup = false;
        const targetElement = $element.parent();

        $document.bind('click', this.hidePopup.bind(this));
        targetElement.on('click', this.onClick.bind(this));

        $scope.$on('destroy', function() {
            $document.unbind('click', this.hidePopup);
            targetElement.off('click', this.onClick);
        });
    }

    onClick(e) {
        e.stopPropagation();
        this.$scope.$apply(() => {
            this.showPopup = !this.showPopup;
        });
    }

    hidePopup() {
        this.$scope.$apply(() => {
            this.showPopup = false;
        });
    }
}

export const popupDirective = function() {
    return {
        restrict: 'EA',
        template: template,
        scope: {},
        controller: PopupDirectiveController,
        controllerAs: 'vm',
        transclude: true,
        replace: true,
    };
};

PopupDirectiveController.$inject = ['$scope', '$element', '$document'];
