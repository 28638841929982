export default function TagModelFactory($modelFactory) {
    const model = $modelFactory('api/tags', {
        actions: {
            query: {
                afterRequest: function(data) {
                    data.items.total = data.total;
                    return data.items;
                },
            },
        },
        instance: {
            $store: function(oldName, newName) {
                return model.$call({
                    method: 'PUT',
                    data: { newText: newName },
                    url: `api/tags/${oldName}`,
                    ignoreErrorHandler: true,
                });
            },
        },
    });

    return model;
}

TagModelFactory.$inject = ['$modelFactory'];
