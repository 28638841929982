import moment from 'moment';

export default class BookInterviewController {
    constructor($http, ngDialog, $scope, $injector) {
        this.ngDialog = ngDialog;
        this.$http = $http;
        this.resume = $scope.resume;
        this.toaster = $injector.get('toaster');
        this.minDate = moment().format('MM/DD/YYYY');
        this.maxDate = moment()
            .add('months', 3)
            .format('MM/DD/YYYY');

        this.title = 'Request an Interview';
        this.interview = {};
        this.interview.timeslots = [
            { id: 1, time: null },
            { id: 2, time: null },
            { id: 3, time: null },
        ];
        this.interview.resume = this.resume.userName;
        this.interview.resumeName = this.resume.name;
    }

    sendData() {
        this.interview.timeslots.forEach(function(timeSlot) {
            if (timeSlot.time)
                timeSlot.time = moment(timeSlot.time).format(
                    'DD/MM/YYYY HH:mm'
                );
        });

        this.$http.post('api/interview', this.interview).then(
            () => {
                this.close();
                this.toaster.pop('info', 'Interview request send');
            },
            error => {
                // eslint-disable-next-line no-console
                console.log('error', error);
            }
        );
    }

    close() {
        this.ngDialog.close(1);
    }

    cancel() {
        this.ngDialog.close(1, 'cancel');
    }
}

BookInterviewController.$inject = ['$http', 'ngDialog', '$scope', '$injector'];
