import mediaSettingsController from './mediaSettings.controller';

export default class MediaSettingsDialog {
    constructor($rootScope, ngDialog, $q) {
        this.ngDialog = ngDialog;
        this.$q = $q;
        this.$rootScope = $rootScope;
    }

    openDialog(file) {
        const newScope = this.$rootScope.$new();
        newScope.file = file;
        const dialog = this.ngDialog.open({
            template: './components/mediaLibrary/mediaSettings.html',
            controller: mediaSettingsController,
            controllerAs: 'vm',
            scope: newScope,
            className: 'vt-dialog',
        });

        return dialog.closePromise;
    }
}

MediaSettingsDialog.$inject = ['$rootScope', 'ngDialog', '$q'];
